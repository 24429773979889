<template>
    <a-spin :spinning="spinning">
        <!-- <page-header :title="l('ProjectUserManager')"></page-header> -->
        <!-- <a-card :bordered="false" style="max-height:600px;overflow-y:scroll;"> -->
            <a-row :gutter="24" type="flex">
                <a-col class="gutter-row" :span="5">
                    <a-input v-model="SearchDto.sceneName"
                             :placeholder="l('TrainName')" />
                </a-col>
                <a-col class="gutter-row" :span="4">
                    <a-button type="primary" @click="search">
                        {{ l("Search") }}
                    </a-button>
                    <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
                </a-col>
            </a-row>

            <a-table :data-source="tableData" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :pagination="pagination" @change="tableChange">
                <a-table-column-group>
                    <a-table-column :key="1" :title="l('TrainHeaderSiteName')" data-index="name" />
                    <a-table-column :key="2" :title="l('Introduction')" data-index="description" />
                    <a-table-column :key="3" :title="l('TrainHeaderEffectiveTime')" data-index="id">
                        <template slot-scope="text, scope">
                            <p>{{l("TrainTime")}}:&nbsp;{{scope.trainTime}}</p>
                            <p>{{l("EndTime")}}:&nbsp;{{scope.endTrainTime}}</p>
                            <p>{{l("ExamTime")}}:&nbsp;{{scope.testTime}}</p>
                            <p>{{l("EndTime")}}:&nbsp;{{scope.endTestTime}}</p>
                        </template>
                    </a-table-column>
                    <a-table-column :key="4" :title="l('TrainHeaderNotify')" data-index="id">
                        <template slot-scope="text, scope">
                            <a-tooltip placement="topRight">
                                <template slot="title">
                                    <p>{{l("StudyNotifyUrl")}}:&nbsp;{{scope.studyNotifyUrl}}</p>
                                    <p>{{l("ExamNotifyUrl")}}:&nbsp;{{scope.testNotifyUrl}}</p>
                                    <p>{{l("CheckUri")}}:&nbsp;{{scope.verificationUrl}}</p>
                                </template>
                                <a-tag color="blue">{{l("Detail")}}</a-tag>
                            </a-tooltip>
                        </template>
                    </a-table-column>
                    <a-table-column :key="5" :title="l('TrainHeaderTemplate')" data-index="template">
                        <template slot-scope="text, scope">
                            <p>{{l("ExamLength")}}:&nbsp;{{scope.testLengthTime}}</p>
                            <p style="margin-left: 10px;"><a-tag color="cyan">{{l("SingerTitle")}}</a-tag>&nbsp;{{scope.singleText}}</p>
                            <p style="margin-left: 10px;"><a-tag color="pink">{{l("MultipleTitle")}}</a-tag>&nbsp;{{scope.multText}}</p>
                        </template>
                    </a-table-column>
                    <a-table-column :key="6" :title="l('Status')" data-index="status">
                        <template slot-scope="text, scope">
                            <a-tag :color="scope.status == 0 ? 'red' : 'blue'">{{scope.status == 0 ? l("Invalid") : l("Effective")}}</a-tag>
                        </template>
                    </a-table-column>
                    <a-table-column :key="7" :title="l('TrainHeaderIsCheck')" data-index="isCheck">
                        <template slot-scope="text, scope">
                            <a-tag :color="scope.isCheck == 0 ? 'red' : 'blue'">{{scope.isCheck == true ? l("NeedClass") : l("NotNeedClass")}}</a-tag>
                        </template>
                    </a-table-column>
                    <a-table-column :key="8" :title="l('OrderDate')" data-index="createTime" />
                </a-table-column-group>
            </a-table>
        <!-- </a-card> -->
        <a-row :gutter="24">
            <a-col span="4" offset="20">
                <a-button type="primary" @click="SubmitSelected">{{l("Sure")}}</a-button>
                <a-button @click="CloseSelected">{{l("Close")}}</a-button>
            </a-col>
        </a-row>
    </a-spin>
</template>
<script>
    import { ModalComponentBase } from "@/shared/component-base";
    import { ModalHelper } from "@/shared/helpers";
    import axios from "axios";
    import moment from "moment";

    export default {
        mixins: [ModalComponentBase],
        name: "projectmodule",
        data: function () {
            return {
                spinning: false,
                SearchDto: {
                    sceneName: ""
                },
                selectedRowKeys: [],
                pagination: { current: 1, pageSize: 5, total: 1 },
                pageSizeOptions: ["5","10", "20", "30", "40"],
                tableData: [],//{ id: '123', name: '����', description: '���Ժ�����', trainTime: '2021-2-2 17:00', endTrainTime: '2021-2-3 17:00', testTime: '2021-2-5 17:00', endTestTime: '2021-2-5 18:00', status: 0, isCheck: true, createTime: '2021-2-1 17:00' }
            };
        },
        methods: {
            search() {
              this.pagination.current = 1
              this.getData()
            },
            getData() {
                this.spinning = true;
                let url = this.$apiUrl + "/api/services/app/Train/PageList";
                let options = {
                    data: { sceneName: this.SearchDto.sceneName, pageSize: this.pagination.pageSize, pageIndex: this.pagination.current },
                    method: "POST",
                    url: url,
                    headers: {
                        "Content-Type": "application/json-patch+json",
                    },
                };
                this.$api
                    .request(options)
                    .then((response) => {
                        if (response.status == 200) {
                            this.tableData.splice(0, this.tableData.length);
                            let res = response.data;
                            if (res.items && res.items.length > 0) {
                                this.tableData = res.items.map((item) => {
                                    return {
                                        id: item.guid,
                                        name: item.sceneName,
                                        description: item.introduce,
                                        trainTime: this.timeFormat(item.effectiveStartTime),
                                        endTrainTime: this.timeFormat(item.effectiveEndTime),
                                        testTime: this.timeFormat(item.testStartTime),
                                        endTestTime: this.timeFormat(item.testEndTime),
                                        status: item.status,
                                        isCheck: item.isRequiredCourse,
                                        createTime: item.createTime,
                                        template: item.trainSceneTemplates,
                                        testLengthTime: item.testLengthTime,
                                        studyNotifyUrl: item.studyNotifyUrl,
                                        testNotifyUrl: item.testNotifyUrl,
                                        verificationUrl: item.verificationUrl,
                                        singleText: this.getTitleText(item.trainSceneTemplates, 1),
                                        multText: this.getTitleText(item.trainSceneTemplates, 2),
                                        passScore: item.passScore
                                    }
                                });

                                this.pagination.total = res.totalItems
                            }
                        }
                    })
                    .catch((e) => {
                        console.error(e);
                        abp.message.warn(this.l("DefaultErrorMessage"));
                    })
                    .finally(() => {
                        this.spinning = false;
                    });
            },
            restCheckStatus() {
                this.selectedRowKeys = [];
            },
            onSelectChange(rows) {
                /*ֻ��ѡ��һ�� */
                if (rows && rows.length > 1) {
                    let selecteds = rows.splice(0, rows.length - 1);
                    this.selectedRowKeys = selecteds;
                }
                //let selecteds = rows.splice(0, rows.length - 1);
                this.selectedRowKeys = rows;
            },
            tableChange(pagination, filters, sorter) {
                const pager = { ...this.pagination };
                pager.current = pagination.current;
                this.pagination = pager;
                this.getData();
            },
            timeFormat(time) {
                return time.toString().replace("T", " ");
            },
            getTitleText(template, type) {
                var rows = template.filter(e => e.questionType == type);
                if (rows && rows.length >= 1) {
                    return rows[0].num + this.l('TitleBody').substring(0, 1) + (rows[0].num * rows[0].score) + this.l('DistributorShare').substring(0,1);
                }
                return '';
            },
            refreshGoFirstPage() {
                this.SearchDto.sceneName = "";
                this.SearchDto.pageIndex = 1;
                this.getData();
            },
            SubmitSelected() {
                if (this.selectedRowKeys.length <= 0) {
                    abp.message.warn(this.l("PleaseSelectAtLeastOneItem"));
                    return false;
                }
                let index = this.selectedRowKeys[0];
                this.success(this.tableData[index]);
            },
            CloseSelected() {
                this.success(false);
            }
        },
        created() {
            this.getData();
        },
    };
</script>
<style lang="less" scoped>
    .btn--container {
        margin-top: 20px;
    }

    .btn--footer {
        margin-bottom: 20px;
    }

    .tbl-edit {
        display: block;
        margin-top: 3px;
    }
</style>
