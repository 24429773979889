<template>
  <a-spin :spinning="spinning">
    <section>
      <!-- <div class="modal-header">
        <div class="modal-title">
          <a-icon type="profile" />
          <span>项目关联表单</span>
        </div>
      </div> -->
      <!-- <react-table
        :table-data="tableData"
        :total-items="totalItems"
        :actions-type="actionsType"
        :columns="columns"
        :hide-row-selection="true"
        :hide-pagination="true"
        :scroll="{x: 0}"
        :current-page="pageNumber"
      > -->
      <ele-table
        :columns="columns"
        :table-data="tableData"
        :total-items="totalItems"
        :is-full="false"
        :actionsType="actionsType"
        :current-page="pageNumber"
        :hide-row-selection="true"
        :hide-pagination="true"
      >
        <a-row :gutter="24" type="flex" style="text-align: right;">
          <a-col class="gutter-row" :span="6" offset="18">
              <!-- <a-button @click="close"><a-icon type="close-circle" />{{l("关闭")}}</a-button> -->
              <a-button type="primary" @click="createOrEdit(null, 'create')"><a-icon type="plus" />{{ l("添加") }}</a-button>
          </a-col>
        </a-row>
      </ele-table>

      <!-- <div class="modal-footer mf">
        <a-button :disabled="saving" @click="close()" type="button">
          <a-icon type="close-circle" />
          {{ l("关闭") }}
        </a-button>
        <a-button
          :loading="saving"
          :type="'primary'"
          @click="createOrEdit(null, 'create')"
        >
          <a-icon type="plus" />
          {{ l("添加") }}
        </a-button>
      </div> -->
    </section>
  </a-spin>
</template>

<script>
import { AppComponentBase, ModalComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import Bus from "@/shared/bus/bus";
import EleTable from "@/components/ele-table";
import ReactModify from "@/components/react-modify";
import { CommonServiceProxy } from "@/shared/common-service";
import { fileDownloadService } from "@/shared/utils";
import moment from "moment";

export default {
  name: "provide",
  mixins: [AppComponentBase, ModalComponentBase],
   props: ["id"],
  components: { EleTable },
  data() {
    return {
      spinning: false,
      commonService: null,
      selectedRowKeys: [],
      name: "",
      filterText: "",
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      request: { sorting: "", maxResultCount: 10, skipCount: 0 },
      // 用户表格
      columns: [
        {
          title: this.l("表单名称"),
          dataIndex: "formName",
          sorter: false,
          width: 240,
          align: "center",
          scopedSlots: { customRender: "formName" },
        },
        {
          title: this.l("表单状态"),
          dataIndex: "formStatus",
          sorter: false,
          width: 100,
          align: "center",
          scopedSlots: { customRender: "formStatus" },
        },
        {
          title: this.l("状态"),
          dataIndex: "status",
          sorter: false,
          width: 100,
          align: "center",
          scopedSlots: { customRender: "status" },
        },
        {
          title: this.l("收集开始时间"),
          dataIndex: "formStartTime",
          sorter: false,
          width: 180,
          align: "center",
          scopedSlots: { customRender: "formStartTime" },
        },
        {
          title: this.l("收集结束时间"),
          dataIndex: "formEndTime",
          sorter: false,
          width: 180,
          align: "center",
          scopedSlots: { customRender: "formEndTime" },
        },
        {
          title: this.l("Actions"),
          dataIndex: "actions",
          sorter: false,
          align: "center",
          fixed: "right",
          scopedSlots: { customRender: "actions" }, //
        },
      ],
      // 用户数据
      tableData: [],
      // btns
      actionsType: {},
      // 保存页面参数中介
      getDataParams: [],
    };
  },
  computed: {},
  created() {
    this.fullData();
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.initActionsType();
  },
  mounted() {
    this.$nextTick(() => {
      this.getData();
    });
  },
  beforeDestroy() {},
  methods: {
    // 拉取数据
    async getData() {
      this.spinning = true;
      let params = {
        projectId: this.id,
      };
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/ProjectForm/GetList",
          params: params,
        });
        this.spinning = false;
        this.tableData = res.map((o) => {
          return {
            ...o,
            status: o.status === "Enabled" ? "正常" : "禁用",
            formStatus: o.formStatus === "Enable" ? "正常" : "禁用",
            formStartTime: o.formStartTime
              ? moment(o.formStartTime).format("YYYY-MM-DD HH:mm:ss")
              : "-",
            formEndTime: o.formEndTime
              ? moment(o.formEndTime).format("YYYY-MM-DD HH:mm:ss")
              : "-",
          };
        });
        // this.totalItems = res.totalCount;
        // this.pagerange = [
        //   (this.pageNumber - 1) * this.request.maxResultCount + 1,
        //   this.pageNumber * this.request.maxResultCount,
        // ];
        // this.totalPages = Math.ceil(res.totalCount / this.request.maxResultCount);
      } catch (error) {
        this.spinning = false;
      }
    },
    // 初始化需要显示的func btn
    initActionsType() {
      let _this = this;
      let obj = {
        type: "project-form",
        isShow: true,
        fns: {
          dropList: [
            {
              granted: true,
              name: this.l("状态变更"),
              icon: "swap",
              fn: (data) => {
                _this._statusChange(data.id);
                // _this.createOrEdit(newV.item, 'preview');
                // _this.createOrEdit(newV.item, 'create');
              },
            },
          ],
        },
      };
      this.actionsType = obj;
    },
    async _statusChange(id) {
      this.spinning = true;
      try {
        const res = await this.commonService.get({
          url: "/api/services/app/ProjectForm/StatusChange",
          params: { id: id },
        });
        this.spinning = false;
        this.$message.success("状态变更成功");
        this.getData();
      } catch (error) {
        this.spinning = false;
        this.$message.error("状态变更失败");
      }
    },
    async _getFormSelectList() {
      this.spinning = true;
      try {
        const res = await this.commonService.get({
          url: "/api/services/app/Form/GetSelectList",
          params: { formType: 0 },
        });
        this.spinning = false;
        let arr = res.map((item) => {
          return {
            label: item.text,
            value: item.value,
          };
        });
        return arr;
      } catch (error) {
        this.spinning = false;
      }
    },
    close() {
      this.$emit('emitclose')
    },
    // 增加修改查看
    async createOrEdit(item, type) {
      const list = [
        {
          label: "采集表单",
          value: "collectionFormId",
          type: "commonSelect",
          disabled: false,
          required: true,
          rules: [
            { required: true, message: '请选择采集表单' },
          ],
          defaultValue: '',
          defaultSelectList: await this._getFormSelectList(),
          changeFn: () => {},
        },
        //,
        //{
        //label: '状态',
        //value: 'status',
        //type: 'switch',
        //disabled: false,
        //required: true,
        //rules: [],
        //changeFn: () => {}
        //},
      ];
      let reqParams = [];
      if (item && item.id) reqParams.push({ key: "id", value: item.id });
      let otherPrams = {};
      ModalHelper.create(
        ReactModify,
        {
          params: {
            type: type, // edit,preview,create
            data: list,
            reqParams: [...reqParams, { key: "projectId", value: this.id }],
            reqUrl: {
              getById: "",
              save: "/api/services/app/ProjectForm/Insert",
            },
            otherParams: {},
            getItemfn: async (res, form, options) => {
              form.setFieldsValue({ ...res });
              if (options) {
                // ...
              }
            },
            submitfn: async (values, queryParams) => {
              let obj = {};
              queryParams.reqParams.map((item) => {
                obj[item.key] = item.value;
              });
              Object.assign(values, obj);
              // console.log(values)
              try {
                let res = await this.commonService.post({
                  url: queryParams.reqUrl.save,
                  params: {
                    ...values,
                    status: queryParams.otherParams.status ? 1 : 0,
                  },
                });
                return true;
              } catch (error) {
                return false;
              }
            },
          },
        },
        {
          isChange: true,
          width: "800px",
        }
      ).subscribe((res) => {
        if (res) {
          // this.clearFilterAndRefresh();
          this.getData();
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.modal-header {
  margin-bottom: 0;
  .anticon {
    margin-right: 10px;
  }
}
.opeattion-container {
  margin: 20px 0;
}
.pagination {
  margin: 10px auto;
  text-align: right;
}
.mf{
  margin: 0;
  margin-top: 10px;
}
</style>
