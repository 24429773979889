<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title">
        <span>新增保险接口配置</span>
      </div>
    </div>
    <div class="s-formbody">
      <a-form :form="form" @submit="handleSubmit" layout="horizontal">
        <a-row :gutter="24">
          <a-col span="12">
            <a-form-item label="AppKey">
              <a-input
                placeholder="请输入AppKey"
                :max="50"
                v-decorator="['appKey', {rules: [{ required: true, message: '请输入AppKey'}]}]"
              />
            </a-form-item>
          </a-col>
          <a-col span="12">
            <a-form-item label="App秘钥">
              <a-input
                placeholder="请输入App秘钥"
                :max="50"
                v-decorator="['appSecret', {rules: [{required: true, message: '请输入App秘钥'}]}]"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item label="接口地址">
              <a-input
                placeholder="请输入接口地址"
                :max="50"
                v-decorator="['host', {rules: [{required: true, message: '请输入接口地址'}]}]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="保险名称">
              <a-select placeholder="请选择保险" v-decorator="['provideName', {rules: [{required: true, message: '请选择保险'}], initialValue: 'HUIXINFU'}]">
                <a-select-option :value="'HUIXINFU'">汇薪福</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item label="其他配置(json)">
              <a-textarea
                :row="4"
                placeholder="请输入json字符串"
                v-decorator="['configJson', {rules: []}]"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="24" style="text-align:center;">
            <a-button type="default" @click="handleClose">关闭</a-button>&nbsp;&nbsp;
            <a-button type="primary" html-type="submit">提交</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
  </a-spin>
</template>

<script>
import { ModalComponentBase } from "@/shared/component-base";
import { CommonServiceProxy } from "@/shared/common-service";

export default {
  mixins: [ModalComponentBase],
  name: 'addinsurance',
  data() {
    return {
      spinning: false,
      commonService: null
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "register" });
  },
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
  },
  methods:{
    handleSubmit() {
      this.form.validateFieldsAndScroll((err, values) => {
        console.info(err, values);
        if (!err) {
          this.spinning = true;
          this.commonService.post({
            url: "/api/services/app/Project/CreateInsuranceConfig",
            params: values,
          }).then(res => {
            this.$message.success("新增成功");
            this.success({ success: true, data: res });
          }).finally(() => {
            this.spinning = false;
          });
        }
      });
    },
    handleClose() {
      this.close();
    }
  }
}
</script>