var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"spinning":_vm.spinning}},[_c('div',{staticClass:"modal-header"},[_c('div',{staticClass:"modal-title"},[(_vm.id && _vm.projectType != 'viewandset')?_c('span',[_vm._v(_vm._s(_vm.l("Edit")))]):_vm._e(),(!_vm.id && _vm.projectType != 'viewandset')?_c('span',[_vm._v(_vm._s(_vm.l("Create")))]):_vm._e()])]),_c('div',{staticClass:"s-formbody"},[_c('a-form',{attrs:{"form":_vm.form,"layout":"horizontal"},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":_vm.l('ProjectHeaderName')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'ProjectName',
                {
                  rules: [
                    {
                      required: true,
                      message: _vm.l('RequiredProjectName'),
                    },
                    {
                      message: _vm.l('MinProjectNameLen'),
                    } ],
                } ]),expression:"[\n                'ProjectName',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: l('RequiredProjectName'),\n                    },\n                    {\n                      message: l('MinProjectNameLen'),\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":_vm.l('ProjectHeaderName'),"disabled":_vm.projectType == 'viewandset'}})],1)],1),_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":_vm.l('ProjectHeaderCode')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'ProjectCode',
                {
                  rules: [
                    {
                      required: true,
                      message: _vm.l('RequiredProjectCode'),
                    } ],
                } ]),expression:"[\n                'ProjectCode',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: l('RequiredProjectCode'),\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":_vm.l('ProjectHeaderCode'),"disabled":""}})],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":_vm.l('ProjectType')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'ProjectType',
                {
                  rules: [
                    {
                      required: true,
                      message: _vm.l('RequiredProjectType'),
                    } ],
                } ]),expression:"[\n                'ProjectType',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: l('RequiredProjectType'),\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":_vm.l('ProjectType')}},_vm._l((_vm.ProjectTypeData),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.text))])}),1)],1)],1),_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":"业务类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'businessType',
                {
                  rules: [
                    {
                      required: true,
                      message: _vm.l('RequiredBusinessType'),
                    } ],
                } ]),expression:"[\n                'businessType',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: l('RequiredBusinessType'),\n                    },\n                  ],\n                },\n              ]"}],attrs:{"Placeholder":"请选择业务类型"}},_vm._l(([
                  { value: 1, label: '灵工' },
                  { value: 2, label: '新经济' } ]),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.label))])}),1)],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":_vm.l('ProjectContract')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'ProjectContract',
                {
                  rules: [
                    {
                      required: true,
                      message: _vm.l('RequiredProjectContract'),
                    } ],
                } ]),expression:"[\n                'ProjectContract',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: l('RequiredProjectContract'),\n                    },\n                  ],\n                },\n              ]"}],attrs:{"show-search":"","placeholder":_vm.l('ProjectContract'),"disabled":_vm.projectType == 'viewandset',"filter-option":_vm.filterOption,"allowClear":""},on:{"change":_vm.ContractChange}},_vm._l((_vm.ContractData),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.label))])}),1)],1)],1),_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":_vm.l('ProjectFinCode')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['ProjectFinCode', { rules: [] }]),expression:"['ProjectFinCode', { rules: [] }]"}],attrs:{"placeholder":_vm.l('ProjectFinCode'),"disabled":_vm.projectType == 'viewandset'}})],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":_vm.l('ProjectHeaderTime')}},[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'ProjectTime',
                {
                  rules: [
                    {
                      required: true,
                      message: _vm.l('RequiredProjectTime'),
                    } ],
                } ]),expression:"[\n                'ProjectTime',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: l('RequiredProjectTime'),\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":['开始日期', '结束日期'],"disabled":_vm.projectType == 'viewandset',"valueFormat":"YYYY-MM-DD"},scopedSlots:_vm._u([{key:"dateRender",fn:function(current){return [_c('div',{staticClass:"ant-calendar-date"},[_vm._v(" "+_vm._s(current.date())+" ")])]}}])})],1)],1),_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":_vm.l('发票信息')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'invoiceInformationalName', // invoiceInformationalId
                {
                  rules: [
                    {
                      required: true,
                      message: _vm.l('RequiredProjectCompany'),
                    } ],
                } ]),expression:"[\n                'invoiceInformationalName', // invoiceInformationalId\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: l('RequiredProjectCompany'),\n                    },\n                  ],\n                },\n              ]"}],attrs:{"disabled":!_vm.CompanyData.length,"placeholder":_vm.l('发票信息')},on:{"click":_vm.selectProjectModal}})],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":_vm.l('ProjectHeaderCompanyName')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'Company',
                {
                  rules: [
                    {
                      required: true,
                      message: _vm.l('RequiredProjectCompany'),
                    } ],
                } ]),expression:"[\n                'Company',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: l('RequiredProjectCompany'),\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"disabled":"","label-in-value":""}},_vm._l((_vm.CompanyData),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":_vm.l('实名认证类型')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'verifiedWay',
                {
                  rules: [
                    {
                      required: true,
                      message: _vm.l('RequiredProjectCompany'),
                    } ],
                  initialValue: '5',
                } ]),expression:"[\n                'verifiedWay',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: l('RequiredProjectCompany'),\n                    },\n                  ],\n                  initialValue: '5',\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"disabled":_vm.projectType == 'viewandset'}},_vm._l((_vm.verifiedWayOptions),function(item,i){return _c('a-select-option',{key:i,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":_vm.l('NC默认编码')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'ncDefaultCode',
                {
                  rules: [
                    {
                      required: true,
                      message: _vm.l('RequiredProjectCompany'),
                    } ],
                  initialValue: '0',
                } ]),expression:"[\n                'ncDefaultCode',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: l('RequiredProjectCompany'),\n                    },\n                  ],\n                  initialValue: '0',\n                },\n              ]"}],staticStyle:{"width":"100%"}},_vm._l((_vm.ncCodeOptions),function(item,i){return _c('a-select-option',{key:i,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":_vm.l('服务事宜')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'Field1',
                {
                  rules: [
                    {
                      required: true,
                      message: _vm.l('服务事宜不能为空'),
                    } ],
                } ]),expression:"[\n                'Field1',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: l('服务事宜不能为空'),\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":_vm.l('服务事宜')}})],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":_vm.l('项目显示名称')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['ShowProjectName', {}]),expression:"['ShowProjectName', {}]"}],attrs:{"placeholder":_vm.l('项目显示名称')}})],1)],1),_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":_vm.l('是否审核加入人员（扫码判定）')}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:(['isAuditJoin', { valuePropName: 'checked' }]),expression:"['isAuditJoin', { valuePropName: 'checked' }]"}]})],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":_vm.l('服务费率%')}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'serviceChargeRate',
                { rules: [], initialValue: 0 } ]),expression:"[\n                'serviceChargeRate',\n                { rules: [], initialValue: 0 },\n              ]"}],attrs:{"min":0,"max":100,"formatter":function (value) { return (value + "%"); },"parser":function (value) { return value.replace('%', ''); }}})],1)],1),_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":_vm.l('是否需要实名后加入')}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'isRealNameJoin',
                { valuePropName: 'checked', initialValue: true } ]),expression:"[\n                'isRealNameJoin',\n                { valuePropName: 'checked', initialValue: true },\n              ]"}],attrs:{"disabled":_vm.projectType == 'viewandset'}})],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":_vm.l('是否需要实名后发放')}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'isRealNamePay',
                { valuePropName: 'checked', initialValue: true } ]),expression:"[\n                'isRealNamePay',\n                { valuePropName: 'checked', initialValue: true },\n              ]"}],attrs:{"disabled":_vm.projectType == 'viewandset'},on:{"change":_vm.handleRealNamePayChange}})],1)],1),_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":_vm.l('是否开启年龄限制')}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'isCheckAge',
                { valuePropName: 'checked', initialValue: true } ]),expression:"[\n                'isCheckAge',\n                { valuePropName: 'checked', initialValue: true },\n              ]"}],attrs:{"disabled":_vm.projectType == 'viewandset'}})],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":_vm.l('发放通道')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'customerProvideConfigId',
                {
                  initialValue: null,
                } ]),expression:"[\n                'customerProvideConfigId',\n                {\n                  initialValue: null,\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"disabled":_vm.projectType == 'viewandset'},on:{"change":_vm.handleCusProvideChange}},[_c('a-select-option',{attrs:{"value":null}},[_vm._v(" 签约公司默认通道 ")]),_vm._l((_vm.configOptions),function(item,i){return _c('a-select-option',{key:i,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.text)+" ")])})],2)],1)],1),_c('a-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.showBalance),expression:"showBalance"}],attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":_vm.l('网商账户余额')}},[_vm._v(" 当前余额：￥"+_vm._s(_vm.cusAmount)+" "),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.handleQueryBalance}},[_vm._v("查询")])],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":_vm.l('提现功能')}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'isWithdrawal',
                { valuePropName: 'checked', initialValue: false } ]),expression:"[\n                'isWithdrawal',\n                { valuePropName: 'checked', initialValue: false },\n              ]"}],attrs:{"disabled":_vm.entity.isAdmin == false || _vm.projectType == 'viewandset'},on:{"change":_vm.handleWithdrawalChange}})],1)],1),_c('a-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.showWithdrawalType),expression:"showWithdrawalType"}],attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":_vm.l('提现发放方式')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['withdrawalType',
                {
                  rules: [],
                }]),expression:"['withdrawalType',\n                {\n                  rules: [],\n                }]"}],staticStyle:{"width":"100%"},attrs:{"disabled":_vm.projectType == 'viewandset'}},_vm._l((_vm.withdrawalTypeData),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.text)+" ")])}),1)],1)],1)],1),_c('a-row',{attrs:{"gutte":24}},[_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":_vm.l('个体工商注册')}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'isCommercialRegist',
                { valuePropName: 'checked', initialValue: false } ]),expression:"[\n                'isCommercialRegist',\n                { valuePropName: 'checked', initialValue: false },\n              ]"}],attrs:{"disabled":_vm.projectType == 'viewandset'},on:{"change":_vm.sohoChange}})],1)],1),_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":"是否需要签署协议后发放"}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'isProjectAgreementSignedRequired',
                { valuePropName: 'checked', initialValue: false } ]),expression:"[\n                'isProjectAgreementSignedRequired',\n                { valuePropName: 'checked', initialValue: false },\n              ]"}],attrs:{"disabled":_vm.projectType == 'viewandset'},on:{"change":_vm.handleSignedChange}})],1)],1)],1),_c('a-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSoho),expression:"showSoho"}],attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":_vm.l('报备单')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'field2',
                {
                  rules: [],
                } ]),expression:"[\n                'field2',\n                {\n                  rules: [],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"disabled":_vm.projectType == 'viewandset'}},_vm._l((_vm.AllSohoData),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":_vm.l('合作对象ID')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['field3', {}]),expression:"['field3', {}]"}],attrs:{"placeholder":_vm.l('合作对象ID')}})],1)],1)],1),_c('a-row',{attrs:{"gutte":24}},[_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":_vm.l('打卡购买保险')}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'isBuyInsurancePunch',
                { valuePropName: 'checked', initialValue: false } ]),expression:"[\n                'isBuyInsurancePunch',\n                { valuePropName: 'checked', initialValue: false },\n              ]"}],attrs:{"disabled":_vm.projectType == 'viewandset'},on:{"change":_vm.insuranceChange}})],1)],1),_c('a-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.showInsurance),expression:"showInsurance"}],attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":"保险配置"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'insuranceConfigId',
                {
                  rules: [],
                } ]),expression:"[\n                'insuranceConfigId',\n                {\n                  rules: [],\n                },\n              ]"}],staticStyle:{"width":"70%"},attrs:{"disabled":_vm.projectType == 'viewandset'}},_vm._l((_vm.AllInsuranceData),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1),_c('a-button',{staticStyle:{"margin-left":"8px"},attrs:{"type":"primary","shape":"circle","icon":"plus"},on:{"click":_vm.handleAddInsuranceCfg}})],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":"24"}},[_c('a-form-item',{attrs:{"label":"是否自动生成结算相关数据"}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'isAutoGenerateSettlementData',
                { valuePropName: 'checked', initialValue: false } ]),expression:"[\n                'isAutoGenerateSettlementData',\n                { valuePropName: 'checked', initialValue: false },\n              ]"}],attrs:{"disabled":_vm.projectType == 'viewandset'}})],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":"24"}},[_c('a-form-item',{attrs:{"label":_vm.l('ProjectRemark')}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['Remark', { rules: [] }]),expression:"['Remark', { rules: [] }]"}],attrs:{"auto-size":{ minRows: 2, maxRows: 6 },"placeholder":_vm.l('ProjectRemark')}})],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":"24"}},[_c('a-form-item',{staticClass:"btn--container",staticStyle:{"text-align":"center","top":"30px"}},[_c('a-button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.l("Cancel"))+" ")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" "+_vm._s(_vm.l("Save"))+" ")])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }