<template>
  <div class="config-list">
    <a-spin :spinning="spinning">
      <div class="modal-header">
        <div class="modal-title">
          <a-icon type="share-alt" />{{ l("Edit") }}
        </div>
      </div>
      <a-card :bordered="false">
        <ul class="list-wrapper">
          <li v-for="(item, index) in list" :key="index">

            <div class="w" v-if="item.signerType === 'Organization'">
              <div class="item title">{{item.signerTypeDesc}}</div>
              <div class="item">
                <a-input v-if="item.organizationUnitName" v-model="item.organizationUnitName" :disabled="true" type='text' />
                <a-select v-else placeholder="请选择" style="width: 70%;" @select="handleOrgSelect(item, index)" @change="handleOrgChange">
                  <a-select-option :value="it.value" v-for="it in orgOptions" :key="it.value">
                    {{it.label}}
                  </a-select-option>
                </a-select>
              </div>
              <!-- <div class="item"><span style="padding: 0 5px;">自动签署</span><a-switch @change="switchChange(item)" v-model="item.isAutoSign"  /></div>
              <div class="item"><span style="padding: 0 5px;">排序</span><a-input style="width: 70%;" type='text' v-model="item.sort" placeholder="排序值" /></div> -->
              <div class="item">
                <span style="padding: 0 5px;">选择印章</span>
                <a-select
                  style="width: 70%;"
                  placeholder="请选择"
                  @change="selectSealChange($event, index)"
                  :default-value="item.sealId"
                >
                  <a-select-option :value="it.value" v-for="(it, i) in item.orgSealOption" :key="i">
                    {{it.label}}
                  </a-select-option>
                </a-select>
              </div>
              <div class="item"><a-button  html-type="submit" @click="_updateSigningBodyConfiguration(item)">更新</a-button></div>
            </div>

            <div class="w" v-if="item.signerType === 'Customer'">
              <div class="item title">{{item.signerTypeDesc}}</div>
              <div class="item">
                <a-input v-if="item.customerName" v-model="item.customerName" :disabled="true" type='text' />
                <a-select v-else placeholder="请选择" style="width: 70%;" @change="handleCusChange">
                  <a-select-option :value="it.value" v-for="it in cusOptions" :key="it.value">
                    {{it.label}}
                  </a-select-option>
                </a-select>
              </div>
              <!-- <div class="item"><span style="padding: 0 5px;">自动签署</span><a-switch @change="switchChange(item)" v-model="item.isAutoSign" /></div>
              <div class="item"><span style="padding: 0 5px;">排序</span><a-input style="width: 70%;" type='text' v-model="item.sort" placeholder="排序值" /></div> -->
              <div class="item">
                <span style="padding: 0 5px;">选择印章</span>
                <a-select
                  style="width: 70%;"
                  placeholder="请选择"
                  @change="selectSealChange($event, index)"
                  :default-value="item.sealId"
                >
                  <a-select-option :value="it.value" v-for="(it, i) in item.cusSealOption" :key="i">
                    {{it.label}}
                  </a-select-option>
                </a-select>
              </div>
              <div class="item"><a-button  html-type="submit" @click="_updateSigningBodyConfiguration(item)">更新</a-button></div>
            </div>

            <div class="w" v-if="item.signerType === 'Personal'">
              <div class="item title">{{item.signerTypeDesc}}</div>
              <div class="item"></div>
              <!-- <div class="item"><span style="padding: 0 5px;">自动签署</span><a-switch :disabled="true" v-model="item.isAutoSign"  /></div>
              <div class="item"><span style="padding: 0 5px;">排序</span><a-input style="width: 70%;" type='text' v-model="item.sort" placeholder="排序值" /></div> -->
              <div class="item">
              </div>
              <div class="item"><a-button  html-type="submit" @click="_updateSigningBodyConfiguration(item)">更新</a-button></div>
            </div>
           
          </li>
        </ul>

      </a-card>

      <div class="modal-footer">
        <a-button :disabled="saving" @click="close()" type="button">
          <a-icon type="close-circle" />
          {{ l("关闭") }}
        </a-button>
        <!-- <a-button :loading="saving" :type="'primary'" @click="createSignType(null)">
          <a-icon type="plus" />
          {{ l("添加") }}
        </a-button> -->
      </div>
    </a-spin>
  </div>
</template>

<script>
import { AppComponentBase, ModalComponentBase } from "@/shared/component-base";

import { ModalHelper } from "@/shared/helpers";
// import { ProjectAgreementServiceProxy } from "./services/project-agreement-proxies";
import { CommonServiceProxy } from "@/shared/common-service";


export default {
  mixins: [AppComponentBase, ModalComponentBase],
  name: "config-list",
  components: {
  },
  data() {
    return {
      projectService: null,
      commonService: null,
      name: "",
      // 表格
      spinning: false,
      // 总数
      totalItems: 0,
      // list-table btns显示参数
      modalShow: false,
      // modal参数
      inputModalParams: {
        destroyOnClose: true,
        maskClosable: false,
        confirmLoading: false,
        visible: false,
        width: 620,
        title: "",
        cancelText: "取消",
        okText: "确认",
        form: {
          id: "",
          name: "",
          sort: "",
          describe: "",
        },
        confirm: () => {},
        cancel: () => {
          this.inputModalParams.confirm = () => {};
          this.inputModalParams.visible = false;
        },
      },
      list: [],
      orgOptions: [],
      cusOptions: [],
      orgSelectVal: {index: null, label: null, value: null}, // 中介
      cusSelectVal: {index: null, label: null, value: null}, // 中介

    };
  },
  computed: {
  },
  created() {
    this.fullData();
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api)
    this._getSigningBodyConfigurations(this.projectAgreementId)
    this._getTopOrganizationUnitOption()
    this._getCustomerOption()
  },
  methods: {
    switchChange(item) {
      if (item.isCanSign === false && item.isAutoSign === true) {
        this.$message.info('不能设置为自动签署');
        item.isAutoSign = !item.isAutoSign
      }
    },
    handleOrgSelect(item, index) {
      // second
      this.list[index].organizationUnitId = this.orgSelectVal.value
      this.list[index].organizationUnitName = this.orgSelectVal.label
      // console.log(this.list)
    },
    handleOrgChange(value) {
      // first
      // console.log(`change ${value}`);
      this.orgSelectVal.label = this.orgOptions.filter(v => v.value === value)[0].label
      this.orgSelectVal.value = value
    },
    handleCusSelect(item, index) {
      // second
      this.list[index].customerId = this.cusSelectVal.value
      this.list[index].customerName = this.cusSelectVal.label
      // console.log(this.list)
    },
    handleCusChange(value) {
      // first
      // console.log(`change ${value}`);
      this.cusSelectVal.label = this.cusOptions.filter(v => v.value === value)[0].label
      this.cusSelectVal.value = value
    },
    selectSealChange(value, index) {
      this.list[index].sealId = value
    },
    async _updateSigningBodyConfiguration(item) {
      let str = item.signerType === "Organization" ? '组织架构' : '客户'
      if (item.signerType === "Organization" && !item.organizationUnitId) {
        this.$message.info(`请选择${str}`);
        return
      }
      if (item.sort === '' || item.sort === null) {
        this.$message.info(`排序值不能为空`);
        return
      }
      this.spinning = true
      const opts = {
        id: item.id,
        organizationUnitId: item.organizationUnitId,
        customerId: item.customerId,
        sort: Number(item.sort),
        isAutoSign: item.isAutoSign,
        customerSaleId: item.customerId ? item.sealId : null,
        organizationSealId: item.organizationUnitId ? item.sealId : null,
      }
      // console.log(opts)
      try {
        let res = await this.commonService.put({
          url: '/api/services/app/ProjectAgreement/UpdateSigningBodyConfiguration',
          params: opts
        })
        this.spinning = false
        this.$notification["success"]({
          message: this.l("SavedSuccessfully"),
        });
        // setTimeout(() => {
        //   this.spinning = false
        //   this.$notification["success"]({
        //     message: this.l("SavedSuccessfully"),
        //   });
        //   this._getSigningBodyConfigurations(this.projectAgreementId)
        // }, 1000)
      } catch (error) {
        this.spinning = false
      }
    },
    async _getSigningBodyConfigurations(projectAgreementId) {
      this.spinning = true
      try {
        let res = await this.commonService.get({
          url: '/api/services/app/ProjectAgreement/GetSigningBodyConfigurations',
          params: {projectAgreementId: projectAgreementId}
        })
        let list = res.map((item, i) => {
          let signerTypeDesc
          if (item.signerType === 'Organization') {
            signerTypeDesc = '组织架构'
            this._canSetAutoSignForOrganization(item.organizationUnitId).then(res => {
              list[i].isCanSign = res
              // console.log(item.isCanSign)
            })
            this._getOrganizationrSealOption(item.organizationUnitId).then(res => {
              list[i].orgSealOption = res
              list[i].sealId = item.organizationSealId ? item.organizationSealId : null
            })
          } else if (item.signerType === 'Customer') {
            signerTypeDesc = '客户'
            this._canSetAutoSignForCustomer(item.customerId).then(res => {
              // item.isCanSign = res
              list[i].isCanSign = res
              // console.log(item.isCanSign)
            })
            this._getCustomerSealOptionn(item.customerId).then(res => {
              list[i].cusSealOption = res
              list[i].sealId = item.customerSealId ? item.customerSealId : null
            })
          } else if (item.signerType === 'Personal') {
            signerTypeDesc = '个人'
          }
          return {
            ...item,
            signerTypeDesc,
            sort: item.sort.toString(),
          }
        });
        // console.log(this.list)
        setTimeout(() => {
          this.list = list
          // console.log(this.list)
          this.spinning = false
        }, 500)
      } catch (error) {
        this.spinning = false
      }
    },
    async _canSetAutoSignForCustomer(customerId) {
      // this.spinning = true
      try {
        let res = await this.commonService.get({
          url: '/api/services/app/ProjectAgreement/CanSetAutoSignForCustomer',
          params: {customerId: customerId}
        })
        // console.log(`${customerId}:, ${res}`)
        // this.spinning = false
        return Promise.resolve(res)
      } catch (error) {
        this.spinning = false
      }
    },
    async _canSetAutoSignForOrganization(orgId) {
      // this.spinning = true
      try {
        let res = await this.commonService.get({
          url: '/api/services/app/ProjectAgreement/CanSetAutoSignForOrganization',
          params: {orgId: orgId}
        })
        // console.log(`${orgId}:, ${res}`)
        // this.spinning = false
        return Promise.resolve(res)

      } catch (error) {
        this.spinning = false
      }
    },
    async _getTopOrganizationUnitOption() {
      this.spinning = true
      try {
        const res = await this.commonService.get({
          url: '/api/services/app/OrganizationUnit/GetTopOrganizationUnitOption',
          params: null
        })
        this.orgOptions = res
        // this.spinning = false
      } catch (error) {
        this.spinning = false
      }
    },
    async _getCustomerOption() {
      this.spinning = true
      try {
        const res = await this.commonService.get({
          url: '/api/services/app/Customer/GetCustomerOption',
          params: null
        })
        this.cusOptions = res
        // this.spinning = false
      } catch (error) {
        this.spinning = false
      }
    },
    async _getOrganizationrSealOption(organizationUnitId) {
      this.spinning = true
      try {
        const res = await this.commonService.get({
          url: '/api/services/app/Organization/GetOrganizationrSealOption',
          params: {organizationUnitId: organizationUnitId}
        })
        // this.spinning = false
        return Promise.resolve(res)
      } catch (error) {
        this.spinning = false
      }
    },
    async _getCustomerSealOptionn(customerId) {
      this.spinning = true
      try {
        const res = await this.commonService.get({
          url: '/api/services/app/Customer/GetCustomerSealOption',
          params: {customerId: customerId}
        })
        // this.spinning = false
        return Promise.resolve(res)
      } catch (error) {
        this.spinning = false
      }
    },
 
  },
};
</script>

<style scoped lang="less">
@import "../../../styles/common.less";

.config-list{
  // min-height: 600px;
}
.list-wrapper{
  padding-left: 0;
  margin-bottom: 0;
  min-height: 100px;
  li{
    list-style: none;
    margin-bottom: 10px;
    .w{
      #flex;
      #flex-vc;
    }
    .item{
      padding: 0 10px;
      #tac;
    }
    .item:nth-of-type(1){
      width: 13%;
      #tar;
    }
    .item:nth-of-type(2){
      width: 25%;
    }
    // .item:nth-of-type(3){
    //   width: 12%;
    //   .ant-switch{
    //     position: relative;
    //     top: -1.5px;
    //   }
    // }
    // .item:nth-of-type(4){
    //   width: 15%;
    // }
    .item:nth-of-type(3){
      // min-width: 23%;
      min-width: 30%;
    }
    .item:nth-of-type(4){
      // width: 12%;
      width: 20%;

    }
  }
}

.modal-footer{
  padding: 10px 0;
  margin: 0;
}
</style>
