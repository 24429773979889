<template>
  <a-spin :spinning="spinning">
    <section>
      <!-- <div class="modal-header">
        <div class="modal-title">
          <a-icon type="unordered-list" />任务模板管理
        </div>
      </div> -->
      <!-- 按钮 -->
      <!-- <a-row :gutter="8" class="btn--container">
        <a-col class="gutter-row" :span="8">
          <a-input
            v-model="filterText"
            :placeholder="l('SearchWithThreeDot')"
          />
        </a-col>
        <a-col class="gutter-row" :span="12">
          <a-button type="primary" @click="getData">
            {{ l("Search") }}
          </a-button>
          <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
        </a-col>
        <a-col class="gutter-row" :span="4" style="float: right">
          <a-button type="primary" @click="notification()">
            <a-icon type="notification" />
            {{ l("通知签署") }}
          </a-button>
        </a-col>
      </a-row> -->
      <!-- <react-table
        :table-data="tableData"
        :actions-type="actionsType"
        :columns="columns"
        :hide-row-selection="true"
        :hide-pagination="true"
        :scroll="{ x: 0 }"
        :current-page="pageNumber"
        @emitSelectChange="selectChange"
      > -->
      <ele-table
        :columns="columns"
        :table-data="tableData"
        :total-items="totalItems"
        :is-full="false"
        :actionsType="actionsType"
        :current-page="pageNumber"
        :hide-row-selection="true"
        :hide-pagination="true"
         @emitSelectChange="selectChange"
      >
        <a-row :gutter="24" type="flex" style="text-align: right">
          <a-col class="gutter-row" :span="6" offset="18">
            <!-- <a-button @click="close"><a-icon type="close-circle" />{{l("关闭")}}</a-button> -->
            <a-button type="primary" @click="addTemplete"
              ><a-icon type="plus" />{{ l("添加") }}</a-button
            >
          </a-col>
        </a-row>
      </ele-table>

      <!-- <div class="modal-footer mf">
        <a-button :disabled="saving" @click="close()" type="button">
          <a-icon type="close-circle" />
          {{ l("关闭") }}
        </a-button>
        <a-button :loading="saving" :type="'primary'" @click="addTemplete">
          <a-icon type="plus" />
          {{ l("添加") }}
        </a-button>
      </div> -->
    </section>

    <a-modal
      centered
      :title="seclectModalParams.title"
      :visible="seclectModalParams.visible"
      :confirm-loading="seclectModalParams.confirmLoading"
      :cancelText="seclectModalParams.cancelText"
      :okText="seclectModalParams.okText"
      :maskClosable="seclectModalParams.maskClosable"
      :destroyOnClose="seclectModalParams.destroyOnClose"
      :width="seclectModalParams.width"
      @ok="seclectModalParams.confirm"
      @cancel="seclectModalParams.cancel"
    >
      <div>
        <a-form :form="form1" v-bind="formItemLayout">
          <a-form-item label="任务模版" has-feedback>
            <a-select
              v-decorator="[
                'taskTemplateId',
                { rules: [{ required: true, message: '请选择任务模板' }] },
              ]"
              placeholder="请选择"
            >
              <a-select-option
                v-for="(item, index) in seclectModalParams.selectList"
                :key="index"
                :value="item.value"
              >
                {{ item.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>

    <a-modal
      centered
      :title="inputModalParams.title"
      :visible="inputModalParams.visible"
      :confirm-loading="inputModalParams.confirmLoading"
      :cancelText="inputModalParams.cancelText"
      :okText="inputModalParams.okText"
      :maskClosable="inputModalParams.maskClosable"
      :destroyOnClose="inputModalParams.destroyOnClose"
      :width="inputModalParams.width"
      @ok="inputModalParams.confirm"
      @cancel="inputModalParams.cancel"
    >
      <div>
        <a-form :form="form2" v-bind="formItemLayout">
          <a-form-item label="作废原因" has-feedback>
            <a-input
              type="textarea"
              :placeholder="l('请输入')"
              v-decorator="[
                'invalidReason',
                {
                  rules: [
                    { required: true, message: this.l('ThisFieldIsRequired') },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </a-spin>
</template>

<script>
import { AppComponentBase, ModalComponentBase } from "@/shared/component-base";
// import { PersonnelAgreementServiceProxy } from "../services/personnel-agreement-proxies";
import { CommonServiceProxy } from "@/shared/common-service";
import { AppConsts } from "@/abpPro/AppConsts";
import { environment } from "@/environments/environment";
import EleTable from "@/components/ele-table";
// import ConfigList from "./config-list";
import { ModalHelper } from "@/shared/helpers";
import moment from "moment";

export default {
  name: "task-form",
  mixins: [AppComponentBase, ModalComponentBase],
  props: ["id"],
  components: { EleTable },
  data() {
    return {
      filterText: "",
      spinning: false,
      // 表单
      formLayout: "horizontal",
      form1: this.$form.createForm(this, { name: "coordinated" }),
      form2: this.$form.createForm(this, { name: "coordinated" }),
      isDisabled: false,

      tableKey: "",
      commonService: null,
      personnelService: null,

      tableData: [],
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      actionsType: {},
      signStatuList: ["未签署", "单方签署", "已经签署", "归档", "已通知"],
      formItemLayout: {
        labelCol: {
          span: 6,
        },
        wrapperCol: {
          span: 16,
        },
      },
      seclectModalParams: {
        destroyOnClose: true,
        maskClosable: false,
        confirmLoading: false,
        visible: false,
        width: 620,
        title: "",
        form: {},
        id: null,
        selectList: [],
        cancelText: "取消",
        okText: "确认",
        confirm: () => {},
        cancel: () => {
          this.seclectModalParams.visible = false;
        },
      },
      inputModalParams: {
        destroyOnClose: true,
        maskClosable: false,
        confirmLoading: false,
        visible: false,
        width: 620,
        title: "",
        form: {},
        id: null,
        selectList: [],
        cancelText: "取消",
        okText: "确认",
        confirm: () => {},
        cancel: () => {
          this.inputModalParams.visible = false;
        },
      },
      // 用户表格
      columns: [
        {
          title: this.l("任务模板名称"),
          dataIndex: "templateName",
          width: 400,
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "templateName" },
        },
        {
          title: this.l("描述"),
          dataIndex: "content",
          width: 400,
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "content" },
        },
        {
          title: this.l("Actions"),
          dataIndex: "actions",
          fixed: "right",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "actions" },
        },
      ],
      selectedRowKeys: [],
      selectedRows: [],
    };
  },
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    // this.personnelService = new PersonnelAgreementServiceProxy(
    //   this.$apiUrl,
    //   this.$api
    // );
    this.fullData(); // 模态框必须,填充数据到data字段

    this.uploadUrl = AppConsts.uploadBaseUrl + "/API/Upload";
    this.subdirName = environment.production ? "abpProd" : "abpDev";
  },
  mounted() {
    console.log(this);
    if (this.id) {
      this.$nextTick(() => {});
      this.getData();
    }
  },
  methods: {
    getData() {
      this.initActionsType();
      // this.tableData = [
      //   {
      //     taskTemplateName: "美团任务专用模板",
      //     remark: "美团快递员专用得",
      //   },
      // ];
      this._getTaskTemplateByProjectId();
      this._agreementTemplateGetSelectData();
    },
    async _getTaskTemplateByProjectId() {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Task/GetTaskTemplateByProjectId",
          params: {
            projectId: this.id,
          },
        });
        this.tableData = res.map((item) => {
          return {
            ...item,
            signTimeStr: item.signTime
              ? moment(item.signTime).format("YYYY-MM-DD HH:mm:ss")
              : "-",
            creationTimeStr: item.creationTime
              ? moment(item.creationTime).format("YYYY-MM-DD HH:mm:ss")
              : "-",
            signStatusStr: this.signStatuList[item.signStatus],
          };
        });
        this.totalItems;
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    async _agreementTemplateGetSelectData() {
      this.spinning = true;
      try {
        let res = await this.commonService.post({
          url: "/api/services/app/Task/GetTemplateList",
          params: {
            templateName: "",
            filterText: "",
            sorting: "",
            maxResultCount: 1000,
            skipCount: 0,
          },
        });
        console.log(res);
        let data = res.items
          .filter((m) => m.isEnabled == true)
          .map((item) => {
            return {
              text: item.templateName,
              value: item.id,
            };
          });
        this.spinning = false;
        this.seclectModalParams.selectList = data;
      } catch (error) {
        this.spinning = false;
      }
    },
    addTemplete() {
      let _this = this;
      this.seclectModalParams.title = "选择任务模版";
      this.seclectModalParams.confirm = function () {
        _this.form1.validateFields(async (err, values) => {
          if (!err) {
            console.log("Received values of form: ", values);
            _this.seclectModalParams.confirmLoading = true;
            try {
              let res = await _this.commonService.post({
                url: "/api/services/app/Task/AddTaskTemplate",
                params: {
                  ...values,
                  projectId: _this.id,
                },
              });
              _this.seclectModalParams.confirmLoading = false;
              _this.seclectModalParams.visible = false;
              _this._getTaskTemplateByProjectId();
            } catch (error) {
              console.log(error);
              _this.seclectModalParams.confirmLoading = false;
            }
          }
        });
      };
      this.seclectModalParams.visible = true;
    },
    editTemplete(item) {
      console.log(item);
      let _this = this;
      this.seclectModalParams.visible = true;
      this.seclectModalParams.title = "修改任务模版";
      setTimeout(() => {
        this.form1.setFieldsValue({
          taskTemplateId: item.templateId,
        });
      }, 200);

      this.seclectModalParams.confirm = function () {
        _this.form1.validateFields(async (err, values) => {
          if (!err) {
            console.log("Received values of form: ", values);
            _this.seclectModalParams.confirmLoading = true;
            try {
              let res = await _this.commonService.post({
                url: "/api/services/app/Task/EditTaskTemplate",
                params: {
                  ...values,
                  projectId: _this.id,
                },
              });
              _this.seclectModalParams.confirmLoading = false;
              _this.seclectModalParams.visible = false;
              _this._getTaskTemplateByProjectId();
            } catch (error) {
              console.log(error);
              _this.seclectModalParams.confirmLoading = false;
            }
          }
        });
      };
    },
    setInvalid(projectAgreementId) {
      let _this = this;
      this.inputModalParams.title = "填写作废原因";
      this.inputModalParams.confirm = function () {
        _this.form2.validateFields(async (err, values) => {
          if (!err) {
            console.log("Received values of form: ", values);
            _this.inputModalParams.confirmLoading = true;
            try {
              let _res = await _this.commonService.get({
                url: "/api/services/app/ProjectAgreement/SetInvalid",
                params: {
                  projectAgreementId: projectAgreementId,
                  invalidReason: values.invalidReason,
                },
              });
              _this.inputModalParams.confirmLoading = false;
              _this.inputModalParams.visible = false;
              _this._getListByProjectId();
            } catch (error) {
              _this.inputModalParams.confirmLoading = false;
            }
          }
        });
      };
      this.inputModalParams.visible = true;
    },
    initActionsType() {
      let _this = this;
      let obj = {
        type: "templete-list",
        isShow: true,
        fns: {
          isShow: true,
          dropList: [
            {
              granted: true,
              name: this.l("Edit"),
              icon: "edit",
              fn: (data) => {
                // _this.createOrEdit(data.id, 'edit');
                _this.editTemplete(data);
              },
            },
            {
              granted: true,
              name: this.l("删除"),
              icon: "delete",
              fn: (data) => {
                console.log(data);
                _this.delPersonnelAgreement(data.id);
              },
            },
          ],
        },
      };
      this.actionsType = obj;
    },
    createOrEdit(id) {
      ModalHelper.create(
        ConfigList,
        {
          projectAgreementId: id,
        },
        {
          isChange: true,
          width: "800px",
        }
      ).subscribe((res) => {
        this._getListByProjectId();
      });
    },
    /**
     * 重置
     */
    refreshGoFirstPage() {
      this.getData();
    },
    delPersonnelAgreement(id) {
      let _that = this;
      this.message.confirm("是否确定删除", (res) => {
        if (res) {
          _that.delData(id);
        }
      });
    },
    async delData(id) {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Task/DelTaskTemplate",
          params: {
            projectTaskTemplateId: id,
          },
        });
        this.refreshGoFirstPage();
        this.$notification["success"]({
          message: this.l("SuccessfullyDeleted"),
        });
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    notification() {
      console.log(this.selectedRowKeys);
      const selectCount = this.selectedRowKeys.length;
      if (selectCount <= 0) {
        abp.message.warn(this.l("PleaseSelectAXPlaceHolder", "通知签署"));
        return;
      }
      this.notificationData();
    },
    async notificationData() {
      this.spinning = true;
      let projectAgreementIdArr = this.selectedRowKeys.map((item) => {
        return item.projectAgreementId;
      });
      console.log(projectAgreementIdArr);
      try {
        let res = await this.commonService.post({
          url:
            "/api/services/app/UnnaturalPersonProjectPersonnelAgreement/SendSignNotice",
          params: {
            personnelId: this.personnelAgreementItem.userId,
            projectAgreementIds: projectAgreementIdArr,
          },
        });
        this.refreshGoFirstPage();
        this.$notification["success"]({
          message: this.l("通知签署成功"),
        });
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    selectChange(data) {
      let { selectedRowKeys, selectedRows } = data;
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRowKeys = selectedRows;
    },
    close() {
      this.$emit("emitclose");
    },
  },
};
</script>

<style scoped lang="less">
// @import "../../../../styles/common.less";

.modal-header {
  .anticon-share-alt {
    margin-right: 10px;
  }
}
.form-wrapper {
  .ant-form-item {
    margin-bottom: 14px;
  }
}
.mf {
  margin: 0;
  margin-top: 10px;
}
</style>
