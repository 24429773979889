<template>
  <a-spin :spinning="spinning">
    <!-- <page-header :title="l('ProjectManager')"></page-header> -->
    <!-- <a-card :bordered="false"> -->
    <!-- table -->
    <!-- <react-table
      :table-data="tableData"
      :total-items="totalItems"
      :showTotal="showTotalFun"
      :actions-type="actionsType"
      :scroll="{ x: 1300 }"
      :columns="columns"
      :hideRowSelection="true"
      :is-full="true"
      :current-page="pageNumber"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSortData="updateSortData"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    > -->
    <ele-table
      :columns="columns"
      :table-data="tableData"
      :total-items="totalItems"
      :is-full="true"
      :actionsType="actionsType"
      :current-page="pageNumber"
      :hide-row-selection="true"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    >
     
      
      <a-row :gutter="4">
        <!-- <a-col :span="4">
          <a-input
            v-model.trim="SearchDto.ProjectName"
            :placeholder="l('ProjectHeaderName')"
          />
        </a-col> -->
        <a-col :span="4">
          <a-input
            v-model.trim="SearchDto.ProjectCode"
            :placeholder="l('项目编码/项目名称')"
          />
        </a-col>
        <!-- <a-col :span="4">
          <a-input
            v-model.trim="SearchDto.CompanyName"
            :placeholder="l('ProjectHeaderCompanyName')"
          />
        </a-col>
        <a-col :span="4">
          <a-input
            v-model.trim="SearchDto.ClientName"
            :placeholder="l('ProjectHeaderCustomerName')"
          />
        </a-col> -->
        <a-col class="gutter-row" :span="4">
              <a-select
                v-model="CustomerId"
                style="width: 100%"
                show-search
                :filter-option="filterOption"
                :placeholder="l('PleaseSelectCustomer')"
                allowClear
              >
                <a-select-option
                  v-for="item in Customers"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
        </a-col>
        <a-col class="gutter-row" :span="3">
              <a-select
                v-model="OrganizationUnitId"
                show-search
                :filter-option="filterOption"
                style="width: 100%"
                :placeholder="l('请选择签约公司')"
                allowClear
              >
                <a-select-option
                  v-for="item in PayCompanys"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
        </a-col>
        <a-col class="gutter-row" :span="4">
          <a-range-picker
            :placeholder="[l('起始日期'), l('结束日期')]"
            v-model="startToEndDate"
          />
        </a-col>

          <a-col class="gutter-row" :span="3">
            <a-select
                v-model="SearchDto.businessType"
                show-search 
                style="width: 100%"
                 :placeholder="l('请选择业务类型')"
                allowClear
              >
                <a-select-option
                  v-for="item in [{value:1, label:'灵工'}, { value:2 , label:'新经济'}]"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>  
        </a-col>  
        <a-col :span="3">
          <a-button
            type="primary"
            @click="search"
            v-if="isGranted('Pages.LGModul.Project.Query')"
          >
            {{ l("Search") }}
          </a-button>
          <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>

     
        </a-col> 
       <a-col :span="2" style="text-align: right">
          <a-button-group>
            <a-button
              type="primary"
              @click="createOrEdit()"
              v-if="isGranted('Pages.LGModul.Project.Create')"
            >
              <a-icon type="plus" />
              {{ l("Create") }}
            </a-button>
            <a-button
              @click="exportToExcel()"
              v-if="isGranted('Pages.LGModul.Project.Export')"
            >
              <a-icon type="file-excel" />
              <span>{{ l("导出") }}</span>
            </a-button>
          </a-button-group>
        </a-col>    
          
      </a-row>

        
    </ele-table>

    <a-modal
      centered
      :title="inputModalParams.title"
      :visible="inputModalParams.visible"
      :confirm-loading="inputModalParams.confirmLoading"
      :maskClosable="inputModalParams.maskClosable"
      :destroyOnClose="inputModalParams.destroyOnClose"
      :width="inputModalParams.width"
      :footer="null"
      @ok="inputModalParams.confirm"
      @cancel="inputModalParams.cancel"
    >
      <div style="text-align: center; position: relative">
        <a-spin spinning="true" tip="加载中..." style="z-index: 1">
          <div style="height: 245px; width: 245px"></div>
        </a-spin>
        <div style="position: absolute; z-index: 2; top: 0; left: 55px">
          <img :src="inputModalParams.url" />
        </div>
        <div style="margin-top: 10px">
          项目名称：{{ inputModalParams.desc.name }}
        </div>
        <div>项目编码：{{ inputModalParams.desc.code }}</div>
      </div>
    </a-modal>
    <!-- </a-card> -->
  </a-spin>
</template>
<script>
import { appSessionService } from "@/shared/abp";
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import {
  ProjectServiceProxy,
  ProjectSearchDto,
} from "@/shared/service-proxies";
import { fileDownloadService } from "@/shared/utils";
import CreateOrUpdate from "./createproject.vue";
import TempleteList from "./project-agreement/templete-list";
import ProjectForm from "./project-form/project-form";
import TaskForm from "./task-form/task-form";
import TrainList from "./train/list.vue";
import moment from "moment";
import EleTable from "@/components/ele-table";
import authedUserList from "../unnaturalperson/project/project-form/authed-user-list";
import DetailForm from "./action/details.vue";
import {initProjectType, initProjectStatus,initProjectBusinessType} from "@/shared/common-service";
export default {
  mixins: [AppComponentBase],
  name: "projectmodule",
  components: { EleTable },
  data: function () {
    return {
      spinning: false,
      SearchDto: {
        ProjectCode: "",
        ProjectName: "",
        ClientName: "",
        CompanyName: "",
        Sorting: "",
        SkipCount: 0,
        MaxResultCount: 50,
        CustomerId:"",
        OrganizationUnitId:"",
        startDate:null,
        endDate:null,
        businessType:undefined,
      },
      
      Proxy: null,
      CompanyData: [{ text: "", value: "" }],
      selectedRowKeys: [],
      totalItems: 0,
      pageNumber: 1,
      totalPages: 1,
      pagerange: [1, 1],
      pageSizeOptions: ["10", "20", "30", "40"],
      request: {
        sorting: "",
      },
      tableData: [],
      columns: [
        {
          title: this.l("ProjectHeaderName"),
          dataIndex: "projectName",
          sorter: false,
          // fixed: "left",
          width: 200,
          ellipsis: true,
          align: "center",
          scopedSlots: { customRender: "projectName" },
        },
        {
          title: this.l("ProjectHeaderCode"),
          dataIndex: "projectCode",
          sorter: true,
          align: "center",
          width: 240,
          scopedSlots: { customRender: "projectCode" },
        },
        {
          title:"业务类型",
          dataIndex:"businessTypeText",
          sorter: false,
          width: 250,
          scopedSlots: { customRender: "businessTypeText" },
        },
        {
          title: this.l("所属客户"),
          dataIndex: "clientName",
          sorter: false,
          width: 250,
          // ellipsis: true,
          //   align: "center",
          scopedSlots: { customRender: "clientName" },
        },
        {
          title: this.l("ProjectHeaderCompanyName"),
          dataIndex: "companyName",
          sorter: false,
          //   align: "center",
          width: 250,
          ellipsis: true,
          scopedSlots: { customRender: "companyName" },
        },
        {
          title: this.l("ProjectType"),
          dataIndex: "projectType",
          sorter: true,
          //   align: "center",
          ellipsis: true,
          // width: 100,
          scopedSlots: { customRender: "projectType" },
          customRender: (text) => {
            if (text == "RenLiWaiBao") {
              return "人力外包";
            }
            if (text == "XiangMuZiXun") {
              return "项目咨询";
            }
            if (text == "TuiGuangFuWu") {
              return "推广服务";
            }
            if (text == "LuYanHuiZhan") {
              return "路演会展";
            }
            if (text == "XinXiJiShu") {
              return "信息技术";
            }
            return "";
          },
        },
        {
          title: this.l("ProjectStatus"),
          dataIndex: "projectStatus",
          sorter: true,
          align: "center",
          // width: 110,
          scopedSlots: { customRender: "projectStatus" },
          customRender: (text) => {
            if (text == "Processing") {
              return "进行中";
            }
            if (text == "NoStart") {
              return "未开始";
            }
            if (text == "Ended") {
              return "执行结束";
            }
            return "";
          },
          //   customRender: (text) => {
          //     if (text == "未开始") {
          //       return <a-tag color="#f50">{{ text }}</a-tag>;
          //     } else if (text == "处理中") {
          //       return <a-tag color="#87d068">{{ text }}</a-tag>;
          //     }
          //     return <a-tag color="#108ee9">{{ text }}</a-tag>;
          //   },
        },
        {
          title: this.l("审核人员"),
          dataIndex: "isAuditJoin",
          //   sorter: true,
          align: "center",
          scopedSlots: { customRender: "isAuditJoin" },
        },
        {
          title: this.l("项目显示名称"),
          dataIndex: "showProjectName",
          sorter: false,
          // fixed: "left",
          width: 200,
          ellipsis: true,
          align: "center",
          scopedSlots: { customRender: "showProjectName" },
        },
        {
          title: this.l("StartDateTime"),
          dataIndex: "beginDate",
          sorter: false,
          //   align: "center",
          // width: "120px",
          customRender: function (text, record, index) {
            if (text) {
              return text.split("T")[0];
            }
            return "";
          },
          scopedSlots: { customRender: "beginDate" },
        },
        {
          title: this.l("EndDateTime"),
          dataIndex: "endDate",
          sorter: false,
          //   align: "center",
          // width: "120px",
          customRender: function (text, record, index) {
            if (text) {
              return text.split("T")[0];
            }
            return "";
          },
          scopedSlots: { customRender: "endDate" },
        },
        {
          title: this.l("Action"),
          dataIndex: "actions",
          scopedSlots: { customRender: "actions" },
          // fixed: "right",
          // align: "center",
          width: 300,
        },
      ],
      inputModalParams: {
        destroyOnClose: true,
        maskClosable: false,
        confirmLoading: false,
        visible: false,
        width: 400,
        title: "项目二维码",
        url: "",
        desc: {
          nane: "",
          code: "",
        },
        cancelText: "取消",
        okText: "确认",
        form: {},
        confirm: () => {},
        cancel: () => {
          this.inputModalParams.confirm = () => {};
          this.inputModalParams.visible = false;
        },
      },
          Customers: [],
              PayCompanys: [],
               CustomerId: undefined,
        OrganizationUnitId: undefined,
               startToEndDate: [],
    };
  },
  methods: {
            filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    initActionsType() {
      let _this = this;
      let obj = {
        type: "project",
        isShow: true,
        fns: {
          isShow: true,
          dropList: [
            {
              granted: this.isGranted("Pages.LGModul.Project.QRCode"),
              name: this.l("二维码"),
              icon: "qrcode",
              fn: (data) => {
                _this.inputModalParams.title = "项目二维码";
                _this.inputModalParams.desc.name = data.projectName;
                _this.inputModalParams.desc.code = data.projectCode;
                if (appSessionService.tenantId == null) {
                  _this.inputModalParams.url = `${this.$apiUrl}/api/services/app/Project/GetProjectQrCode?projectId=${data.id}`;
                } else {
                  _this.inputModalParams.url = `${this.$apiUrl}/api/services/app/Project/GetProjectQrCode?projectId=${data.id}&tenantId=${appSessionService.tenantId}`;
                }
                _this.inputModalParams.visible = true;
                _this.inputModalParams.title = "项目二维码";
              },
            },
            {
              granted: this.isGranted("Pages.LGModul.Project.QRCode"),
              name: this.l("H5二维码"),
              icon: "qrcode",
              fn: (data) => {
                _this.inputModalParams.title = "项目H5二维码";
                _this.inputModalParams.desc.name = data.projectName;
                _this.inputModalParams.desc.code = data.projectCode;

                if (appSessionService.tenantId == null)
                  _this.inputModalParams.url = `${this.$apiUrl}/api/services/app/Project/GetH5ProjectQrCode?projectId=${data.id}`;
                else
                  _this.inputModalParams.url = `${this.$apiUrl}/api/services/app/Project/GetH5ProjectQrCode?projectId=${data.id}&tenantId=${appSessionService.tenantId}`;

                _this.inputModalParams.visible = true;
              },
            },
            {
              granted: this.isGranted("Pages.LGModul.Project.Edit"),
              name: this.l("Edit"),
              icon: "edit",
              fn: (data) => {
                _this.createOrEdit(data.id);
              },
            },
            {
              delete: true,
              granted: this.isGranted("Pages.LGModul.Project.Delete"),
              name: this.l("Delete"),
              fn: (data) => {
                _this.deleteItem(data.id);
              },
            },
            // {
            //   granted: this.isGranted("Pages.LGModul.Project.Contract"),
            //   name: this.l("协议管理"),
            //   icon: "profile",
            //   fn: (data) => {
            //     _this.projectAgreementModal(data.id);
            //   },
            // },
            // {
            //   granted: this.isGranted("Pages.LGModul.Project.Form"),
            //   name: this.l("表单管理"),
            //   icon: "profile",
            //   fn: (data) => {
            //     _this.projectFormModal(data.id);
            //   },
            // },
            // {
            //   granted: this.isGranted("Pages.LGModul.Project.TaskSetting"),
            //   name: this.l("任务配置"),
            //   icon: "profile",
            //   fn: (data) => {
            //     _this.taskFormModel(data.id);
            //   },
            // },
            // {
            //   granted: this.isGranted("Pages.LGModul.Project.TrainSetting"),
            //   name: this.l("培训配置"),
            //   icon: "unordered-list",
            //   fn: (data) => {
            //     _this.tranListModel(data.id);
            //   },
            // },
            // {
            //   granted: this.isGranted("Pages.LGModul.Project.Permission"),
            //   name: "授权",
            //   icon: "edit",
            //   fn: (data) => {
            //     _this.openAuthForm(data.id, data);
            //   },
            // },
          ],
          detailList: [
            {
              granted: this.isGranted("Pages.LGModul.Project.ViewConfig"),
              name: this.l("查看与配置"),
              icon: "setting",
              width: "50%",
              fn: (data) => {
                _this._projectDetail(data.id, "viewandset");
              },
            },
            {
              granted: this.isGranted("Pages.LGModul.Project.Config"),
              name: this.l("项目配置"),
              icon: "setting",
              width: "50%",
              fn: (data) => {
                console.info(data);
                _this._projectDetail(data.id);
              },
            },
          ],
          // delete: {
          //   granted: this.isGranted("Pages.LGModul.Project.Delete"),
          //   name: this.l("Delete"),
          //   fn: (data) => {
          //     _this.deleteItem(data.id);
          //   },
          // },
        },
      };
      this.actionsType = obj;
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.request.sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.pageNumber = page;
      this.getData();
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.SearchDto.MaxResultCount = size;
      this.getData();
    },
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    // 清除条件并刷新
    clearFilterAndRefresh() {
      this.refreshGoFirstPage();
    },
    // 分页事件
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },

    openAuthForm(id, data) {
      console.log("openAuthForm");
      console.log(id);
      ModalHelper.create(
        authedUserList,

        { id: id, projectType: 1 },
        {
          isChange: true,
          width: "800px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    search() {
      this.pageNumber = 1;
      this.SearchDto.SkipCount = 0;
      this.getData();
    },
    getData() {
      this.spinning = true;
      // debugger;
      var dto = new ProjectSearchDto();
           var  startDate =!this.startToEndDate[0]? undefined : this.startToEndDate[0].format('YYYY-MM-DD');
     var  endDate = !this.startToEndDate[1]? undefined : this.startToEndDate[1].format('YYYY-MM-DD');
      this.SearchDto.CustomerId = this.CustomerId;
      this.SearchDto.OrganizationUnitId = this.OrganizationUnitId;
      this.SearchDto.StartDate = startDate;
       
      this.SearchDto.EndDate = endDate;
      this.SearchDto.Sorting = this.request.sorting;
      this.SearchDto.SkipCount =
        (this.pageNumber - 1) * this.SearchDto.MaxResultCount;
      dto = dto.formJson(this.SearchDto);
      
      this.Proxy.GetList(dto)
        .finally(() => {
          this.spinning = false;
        })
        .then((res) => {
          this.tableData = res.items.map((item) => {
            return {
              ...item,
              isAuditJoin: item.isAuditJoin ? "是" : "否",
              projectType: initProjectType(item.projectType),
              projectStatus: initProjectStatus(item.projectStatus),
              businessTypeText:initProjectBusinessType(item.businessType),
              beginDate: moment(item.beginDate).format("YYYY-MM-DD"),
              endDate: moment(item.endDate).format("YYYY-MM-DD"),
            };
          });
          this.totalItems = res.totalCount;
          this.totalPages = Math.ceil(
            res.totalCount / this.SearchDto.MaxResultCount
          );
          this.pagerange = [
            (this.pageNumber - 1) * this.SearchDto.MaxResultCount + 1,
            this.pageNumber * this.SearchDto.MaxResultCount,
          ];
        });
    },
    refreshGoFirstPage() {
      this.SearchDto.ProjectName = "";
      this.SearchDto.ProjectCode = "";
      this.SearchDto.ClientName = "";
      this.SearchDto.CompanyName = "";
      this.SearchDto.Sorting = "";
      this.pageNumber = 1;
      this.SearchDto.SkipCount = 0;
                this.CustomerId = undefined;
      this.OrganizationUnitId = undefined;
          this.startToEndDate = [];
      this.getData();
    },
    createOrEdit(id) {
      ModalHelper.create(
        CreateOrUpdate,
        { _id: id },
        {
          width: "750px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    exportToExcel() {
      if (this.totalItems <= 0) {
        this.$message.warning(this.l("ProjectExportEmpty"));
        return false;
      }
      // this.spinning = true;
      // this.Proxy.getToExcelFile().then((result) => {
      //   this.spinning = false;
      //   fileDownloadService.downloadTempFile(result);
      // });
      let startDate = !this.startToEndDate[0] ? undefined : this.startToEndDate[0].format('YYYY-MM-DD');
      let endDate = !this.startToEndDate[1] ? undefined : this.startToEndDate[1].format('YYYY-MM-DD');

      let data = {
      CustomerId : this.CustomerId,
      OrganizationUnitId : this.OrganizationUnitId,
      StartDate : startDate,
      EndDate : endDate,
      Sorting : this.request.sorting,
      SkipCount : (this.pageNumber - 1) * this.SearchDto.MaxResultCount,
      ProjectCode:this.SearchDto.ProjectCode,
      };
      this.$api.request({
          url: this.$apiUrl + '/api/services/app/Project/GetProjectsToExcelFile',
          method: 'GET',
          params: data
      }).then((result) => {
        this.spinning = false;
        fileDownloadService.downloadTempFile(result.data);
      }).catch(e => {
        this.spinning = false;
        console.error(e);
      });
    },
    batchDelete() {
      const selectCount = this.selectedRowKeys.length;
      if (selectCount <= 0) {
        abp.message.warn(this.l("PleaseSelectAtLeastOneItem"));
        return;
      }
      this.message.confirm(
        this.l("ConfirmDeleteXItemsWarningMessage", selectCount),
        (res) => {
          if (res) {
            const ids = _.map(this.selectedRowKeys);
            this.spinning = true;
            this.Proxy.BatchDelete(ids)
              .finally(() => {
                this.spinning = false;
              })
              .then(() => {
                this.selectedRowKeys = [];
                this.refreshGoFirstPage();
                this.$notification["success"]({
                  message: this.l("SuccessfullyDeleted"),
                });
              });
          }
        }
      );
    },
    deleteItem(item) {
      this.spinning = true;
      this.Proxy.DeleteProject(item.id)
        .finally(() => {
          this.spinning = false;
        })
        .then(() => {
          this.refreshGoFirstPage();
          this.$notification["success"]({
            message: this.l("SuccessfullyDeleted"),
          });
        });
    },
    restCheckStatus() {
      this.selectedRowKeys = [];
    },
    searchCompanyName(value, isAll) {
      if (isAll || (value && value.length > 2)) {
        this.Proxy.getCompanyName(value).then((res) => {
          this.CompanyData.splice(1, this.CompanyData.length);
          if (res.totalCount > 0) {
            for (let i = 0; i < res.items.length; i++) {
              this.CompanyData.push({
                text: res.items[i].name,
                value: res.items[i].name,
              });
            }
          }
        });
      }
    },
    projectAgreementModal(id) {
      ModalHelper.create(
        TempleteList,
        { id: id },
        {
          isChange: true,
          width: "1200px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    projectFormModal(id) {
      ModalHelper.create(
        ProjectForm,
        { id: id },
        {
          isChange: true,
          width: "1200px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    taskFormModel(id) {
      ModalHelper.create(
        TaskForm,
        { id: id },
        {
          isChange: true,
          width: "1200px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    /*培训配置*/
    tranListModel(id) {
      ModalHelper.create(
        TrainList,
        { id: id },
        {
          isChange: true,
          width: "1000px",
        }
      );
    },
    _projectDetail(id, type) {
      console.info(id);
      ModalHelper.create(
        DetailForm,
        { id: id, type: type },
        {
          isChange: true,
          width: "1000px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
        getAllPayCompany() {
      this.spinning = true;
      this.$api
        .request({
          // url: this.$apiUrl + "/api/services/app/Organization/GetAllSelectList",
          url:
            this.$apiUrl + "/api/services/app/Organization/GetSelectList",
          method: "GET",
        })
        .then((res) => {
          this.PayCompanys.splice(0, this.PayCompanys.length);
          if (res && res.data) {
            this.PayCompanys = res.data.map((item) => {
              return {
                value: item.value,
                label: item.text,
              };
            });
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    getAllCutomer() {
      let options = {
        url: this.$apiUrl + "/api/services/app/Customer/GetCustomerOption",
        method: "GET",
      };
      this.$api
        .request(options)
        .then((res) => {
          if (res.status == 200) {
            this.Customers.splice(1, this.Customers.length - 1);
            if (res.data) {
              for (let i = 0; i < res.data.length; i++) {
                this.Customers.push(res.data[i]);
                // console.log(this.Customers)
              }
            }
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
  created() {
    this.Proxy = new ProjectServiceProxy(this.$apiUrl, this.$api);
    this.getData();
           this.getAllPayCompany();
    this.getAllCutomer();
    this.initActionsType();
  },
};
</script>
<style lang="less" scoped>
.btn--container {
  margin-top: 20px;
}

.btn--footer {
  margin-bottom: 20px;
}
</style>
