<template>
    <a-spin :spinning="spinning">
        <!-- <page-header :title="l('ProjectUserManager')"></page-header> -->
        <!-- <a-card :bordered="false"> -->
            
            <!-- <a-table :data-source="tableData">
                    <a-table-column :key="1" :title="l('TrainHeaderSiteName')" data-index="trainName" />
                    <a-table-column :key="2" :title="l('Introduction')" data-index="trainIntroduce" />
                    <a-table-column :key="8" :title="l('OrderDate')" data-index="creationTime">
                        <template slot-scope="text">
                            {{timeFormat(text)}}
                        </template>
                    </a-table-column>
                    <a-table-column :key="9" :title="l('Action')" data-index="id">
                        <template slot-scope="text, scope">
                            <a class="tbl-edit" v-for="item in ActionList" :key="item.key" @click="emitItem(item, scope)">
                                <span><a-icon :type="item.icon" />{{item.name}}&nbsp;&nbsp;</span>
                            </a>
                        </template>
                    </a-table-column>
            </a-table> -->
        <!-- </a-card> -->

        <!-- <react-table
          ref="listTable"
          :table-data="tableData"
          :actions-type="actionsType"
          :columns="columns"
          :hideRowSelection="true"
          :hidePagination="true"
          :scroll="{x: 0}"
          :current-page="pageNumber"
        > -->
        <ele-table
            :columns="columns"
            :table-data="tableData"
            :total-items="totalItems"
            :is-full="false"
            :actionsType="actionsType"
            :current-page="pageNumber"
            :hide-row-selection="true"
            :hide-pagination="true"
        >
            <a-row :gutter="24" type="flex" style="text-align: right;">
                <a-col class="gutter-row" :span="4" offset="20">
                    <!-- <a-button @click="refreshGoFirstPage"> {{ l("Refresh") }}</a-button> -->
                    <a-button type="primary" @click="createOrEdit">{{l("Create")}}</a-button>
                </a-col>
            </a-row>
        </ele-table>

    </a-spin>
</template>
<script>
    import { ModalComponentBase } from "@/shared/component-base";
    import { ModalHelper } from "@/shared/helpers";
    import axios from "axios";
    import Create from "./create.vue";
    import Detail from "./detail.vue";
    import moment from "moment";
    import EleTable from "@/components/ele-table";

    
    export default {
        mixins: [ModalComponentBase],
           props: ["id"],
        name: "projectmodule",
        components: {EleTable},
        data: function () {
            return {
                spinning: false,
                selectedRowKeys: [],
                ActionList: [],
                tableData: [],
                // 总数
                totalItems: 0,
                // 当前页码
                pageNumber: 1,
                // 共多少页
                totalPages: 1,
                // 条数显示范围
                pagerange: [1, 1],
                // 显示条数
                pageSizeOptions: ["10", "20", "30", "40"],
                request: { sorting: "", maxResultCount: 30, skipCount: 0 }, //sorting {排序项 ASC/DESC}
                columns: [
                    {
                        title: this.l("TrainHeaderSiteName"),
                        dataIndex: "trainName",
                        sorter: false,
                        width: 300,
                        align: "center",
                        scopedSlots: { customRender: "trainName" },
                    },
                    {
                        title: this.l("Introduction"),
                        dataIndex: "trainIntroduce",
                        sorter: false,
                        width: 250,
                        align: "center",
                        scopedSlots: { customRender: "trainIntroduce" },
                    },
                    {
                        title: this.l("OrderDate"),
                        dataIndex: "creationTime",
                        sorter: false,
                        width: 200,
                        align: "center",
                        customRender: (text, record) => {
                            return text.split('T')[0]
                        },
                        scopedSlots: { customRender: "creationTime" },
                    },
                    {
                        title: this.l("Actions"),
                        dataIndex: "actions",
                        fixed: "right",
                        sorter: false,
                        align: "center",
                        scopedSlots: { customRender: "actions" },
                    },
                ],
                actionsType: {}
            };
        },
        methods: {
            initActionsType() {
                let _this = this;
                let obj = {
                    type: "seal",
                    isShow: true,
                    fns: {
                    dropList: [
                        {
                            granted: this.isGranted("Pages.BillPermissions.Update"),
                            name: this.l("Details"),
                            icon: "edit",
                            fn: (data) => {
                                ModalHelper.create(Detail, { id: data.trainId }, { isChange: true, width: "800px" });
                            },
                        },
                    ],
                    delete: { granted: true, name: this.l("Delete"), fn: (data) => {
                      abp.message.confirm(_this.l("ConfirmDeleteWarningMessage"), _this.l("HintInformation"), res => {
                        if (res) {
                            _this.spinning = true;
                            let url = _this.$apiUrl + "/api/services/app/Project/DeleteProjectTrains";
                            let options = {
                                data: [data.id],
                                method: "POST",
                                url: url,
                                headers: {
                                    "Content-Type": "application/json-patch+json",
                                },
                            };
                            _this.$api.request(options).then(res => {
                                if (res.status == 200) {
                                    abp.message.success(_this.l("SuccessfullyDeleted"));
                                    _this.getData();
                                } else {
                                    abp.message.error(res.statusText);
                                }
                            }).catch((e) => {
                                console.error(e);
                                abp.message.warn(_this.l("DefaultErrorMessage"));
                            }).finally(() => {
                                _this.spinning = false;
                            });
                        }
                    });
                    } },
                    },
                };
                this.actionsType = obj;
            },
            // 分页事件
            showTotalFun() {
                return this.l(
                    "GridFooterDisplayText",
                    this.pageNumber,
                    this.totalPages,
                    this.totalItems,
                    this.pagerange[0],
                    this.pagerange[1]
                );
            },
            // 分页
            updatePageChange(newV) {
                let { page, pageSize } = newV;
                this.pageNumber = page;
                this.request.skipCount = (page - 1) * this.request.maxResultCount;
                this.getData();
            },
            updateShowSizeChange(newV) {
                let { current, size } = newV;
                this.pageNumber = 1;
                this.request.maxResultCount = size;
                this.getData();
            },
            emitItem(item, scope) {
                if (item.click && typeof item.click == "function") {
                    item.click(scope);
                }
            },
            getData() {
                this.spinning = true;
                let url = this.$apiUrl + "/api/services/app/Project/GetAllProjectTrains";
                let options = {
                    params: { projectId: this.id },
                    method: "GET",
                    url: url,
                    headers: {
                        "Content-Type": "application/json-patch+json",
                    },
                };
                this.$api
                    .request(options)
                    .then((response) => {
                        if (response.status == 200) {
                            this.tableData.splice(0, this.tableData.length);
                            if (response.data) {
                                let res = response.data;
                                this.tableData = [...res];
                                this.totalItems = res.length;
                                this.pagerange = [
                                    (this.pageNumber - 1) * this.request.maxResultCount + 1,
                                    this.pageNumber * this.request.maxResultCount,
                                ];
                                this.totalPages = Math.ceil(
                                    res.length / this.request.maxResultCount
                                );
                            }
                        }
                    })
                    .catch((e) => {
                        console.error(e);
                        abp.message.warn(this.l("DefaultErrorMessage"));
                    })
                    .finally(() => {
                        this.spinning = false;
                    });
            },
            createOrEdit() {
                ModalHelper.create(Create, { projectId: this.id }, { isChange: true,width: "800px" }).subscribe((res) => {
                    if (res) {
                        this.getData();
                    }
                });
            },
            timeFormat(time) {
                return time.toString().split('T')[0];
            },
            refreshGoFirstPage() {
                this.getData();
            }
        },
        created() {
            this.fullData();
        },
        mounted() {
            this.initActionsType();
            this.getData();
        }
    };
</script>
<style lang="less" scoped>
    .btn--container {
        margin-top: 20px;
    }

    .btn--footer {
        margin-bottom: 20px;
    }

    .tbl-edit {
        display: block;
        margin-top: 3px;
    }
</style>
