<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title">
        <span v-if="id && projectType != 'viewandset'">{{ l("Edit") }}</span>
        <span v-if="!id && projectType != 'viewandset'">{{ l("Create") }}</span>
      </div>
    </div>
    <div class="s-formbody">
      <a-form :form="form" @submit="handleSubmit" layout="horizontal">
        <a-row :gutter="24">
          <a-col span="12">
            <!-- 项目名称 -->
            <a-form-item :label="l('ProjectHeaderName')">
              <a-input
                :placeholder="l('ProjectHeaderName')"
                :disabled="projectType == 'viewandset'"
                v-decorator="[
                  'ProjectName',
                  {
                    rules: [
                      {
                        required: true,
                        message: l('RequiredProjectName'),
                      },
                      {
                        message: l('MinProjectNameLen'),
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col span="12">
            <!-- 项目编码 -->
            <a-form-item :label="l('ProjectHeaderCode')">
              <a-input
                :placeholder="l('ProjectHeaderCode')"
                disabled
                v-decorator="[
                  'ProjectCode',
                  {
                    rules: [
                      {
                        required: true,
                        message: l('RequiredProjectCode'),
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col span="12">
            <!-- 项目类型 -->
            <a-form-item :label="l('ProjectType')">
              <a-select
                :placeholder="l('ProjectType')"
                v-decorator="[
                  'ProjectType',
                  {
                    rules: [
                      {
                        required: true,
                        message: l('RequiredProjectType'),
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="item in ProjectTypeData"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.text }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>

          <a-col span="12">
            <!-- 业务类型 -->
            <a-form-item label="业务类型">
              <a-select
                Placeholder="请选择业务类型"
                v-decorator="[
                  'businessType',
                  {
                    rules: [
                      {
                        required: true,
                        message: l('RequiredBusinessType'),
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="item in [
                    { value: 1, label: '灵工' },
                    { value: 2, label: '新经济' },
                  ]"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.label }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col span="12">
            <!--销售合同-->
            <a-form-item :label="l('ProjectContract')">
              <a-select
                show-search
                :placeholder="l('ProjectContract')"
                :disabled="projectType == 'viewandset'"
                v-decorator="[
                  'ProjectContract',
                  {
                    rules: [
                      {
                        required: true,
                        message: l('RequiredProjectContract'),
                      },
                    ],
                  },
                ]"
                @change="ContractChange"
                :filter-option="filterOption"
                allowClear
              >
                <a-select-option
                  v-for="item in ContractData"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.label }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>

          <a-col span="12">
            <!--财务编码-->
            <a-form-item :label="l('ProjectFinCode')">
              <a-input
                :placeholder="l('ProjectFinCode')"
                :disabled="projectType == 'viewandset'"
                v-decorator="['ProjectFinCode', { rules: [] }]"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col span="12">
            <!--项目时间-->
            <a-form-item :label="l('ProjectHeaderTime')">
              <a-range-picker
                :placeholder="['开始日期', '结束日期']"
                :disabled="projectType == 'viewandset'"
                valueFormat="YYYY-MM-DD"
                v-decorator="[
                  'ProjectTime',
                  {
                    rules: [
                      {
                        required: true,
                        message: l('RequiredProjectTime'),
                      },
                    ],
                  },
                ]"
              >
                <template slot="dateRender" slot-scope="current">
                  <div class="ant-calendar-date">
                    {{ current.date() }}
                  </div>
                </template>
              </a-range-picker>
            </a-form-item>
          </a-col>

          <a-col span="12">
            <!--发票信息-->
            <!-- <a-form-item :label="l('InvoiceInformation')"> -->
            <a-form-item :label="l('发票信息')">
              <!-- <a-select
                show-search
                v-decorator="[
                  'invoiceInformationalId',
                  {
                    rules: [
                      {
                        required: true,
                        message: l('RequiredProjectCompany'),
                      },
                    ],
                  },
                ]"
                style="width: 100%"
                :filter-option="filterOption"
                allowClear
              >
              <a-select-option
                :value="item.value"
                v-for="(item, index) in invoiceOptions"
                :key="index"
              >
                {{ item.label }}
              </a-select-option>
              </a-select> -->
              <a-input
                :disabled="!CompanyData.length"
                :placeholder="l('发票信息')"
                @click="selectProjectModal"
                v-decorator="[
                  'invoiceInformationalName', // invoiceInformationalId
                  {
                    rules: [
                      {
                        required: true,
                        message: l('RequiredProjectCompany'),
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col span="12">
            <!--签约公司-->
            <a-form-item :label="l('ProjectHeaderCompanyName')">
              <a-select
                disabled
                label-in-value
                v-decorator="[
                  'Company',
                  {
                    rules: [
                      {
                        required: true,
                        message: l('RequiredProjectCompany'),
                      },
                    ],
                  },
                ]"
                style="width: 100%"
              >
                <a-select-option
                  :value="item.value"
                  v-for="(item, index) in CompanyData"
                  :key="index"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col span="12">
            <a-form-item :label="l('实名认证类型')">
              <a-select
                :disabled="projectType == 'viewandset'"
                v-decorator="[
                  'verifiedWay',
                  {
                    rules: [
                      {
                        required: true,
                        message: l('RequiredProjectCompany'),
                      },
                    ],
                    initialValue: '5',
                  },
                ]"
                style="width: 100%"
              >
                <a-select-option
                  :value="item.value"
                  v-for="(item, i) in verifiedWayOptions"
                  :key="i"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col span="12">
            <a-form-item :label="l('NC默认编码')">
              <a-select
                v-decorator="[
                  'ncDefaultCode',
                  {
                    rules: [
                      {
                        required: true,
                        message: l('RequiredProjectCompany'),
                      },
                    ],
                    initialValue: '0',
                  },
                ]"
                style="width: 100%"
              >
                <a-select-option
                  :value="item.value"
                  v-for="(item, i) in ncCodeOptions"
                  :key="i"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col span="12">
            <!--服务事宜-->
            <a-form-item :label="l('服务事宜')">
              <a-input
                :placeholder="l('服务事宜')"
                v-decorator="[
                  'Field1',
                  {
                    rules: [
                      {
                        required: true,
                        message: l('服务事宜不能为空'),
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col span="12">
            <!--项目显示名称-->
            <a-form-item :label="l('项目显示名称')">
              <a-input
                :placeholder="l('项目显示名称')"
                v-decorator="['ShowProjectName', {}]"
              />
            </a-form-item>
          </a-col>

          <a-col span="12">
            <!-- 是否审核加入人员（扫码判定）-->
            <a-form-item :label="l('是否审核加入人员（扫码判定）')">
              <a-switch
                v-decorator="['isAuditJoin', { valuePropName: 'checked' }]"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col span="12">
            <!--是否需要实名后发放-->
            <a-form-item :label="l('服务费率%')">
              <a-input-number
                :min="0"
                :max="100"
                v-decorator="[
                  'serviceChargeRate',
                  { rules: [], initialValue: 0 },
                ]"
                :formatter="(value) => `${value}%`"
                :parser="(value) => value.replace('%', '')"
              />
            </a-form-item>
          </a-col>

          <a-col span="12">
            <!--是否需要实名后加入-->
            <a-form-item :label="l('是否需要实名后加入')">
              <a-switch
                :disabled="projectType == 'viewandset'"
                v-decorator="[
                  'isRealNameJoin',
                  { valuePropName: 'checked', initialValue: true },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col span="12">
            <!--是否需要实名后发放-->
            <a-form-item :label="l('是否需要实名后发放')">
              <a-switch
                :disabled="projectType == 'viewandset'"
                @change="handleRealNamePayChange"
                v-decorator="[
                  'isRealNamePay',
                  { valuePropName: 'checked', initialValue: true },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col span="12">
            <!--是否开启年龄限制-->
            <a-form-item :label="l('是否开启年龄限制')">
              <a-switch
                :disabled="projectType == 'viewandset'"
                v-decorator="[
                  'isCheckAge',
                  { valuePropName: 'checked', initialValue: true },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col span="12">
            <!--发放通道-->
            <a-form-item :label="l('发放通道')">
              <a-select
                :disabled="projectType == 'viewandset'"
                v-decorator="[
                  'customerProvideConfigId',
                  {
                    initialValue: null,
                  },
                ]"
                style="width: 100%"
                @change="handleCusProvideChange"
              >
                <a-select-option :value="null">
                  签约公司默认通道
                </a-select-option>
                <a-select-option
                  :value="item.value"
                  v-for="(item, i) in configOptions"
                  :key="i"
                >
                  {{ item.text }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col span="12" v-show="showBalance">
            <a-form-item :label="l('网商账户余额')">
              当前余额：￥{{cusAmount}}&nbsp;<a-button type="primary" @click="handleQueryBalance">查询</a-button>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col span="12">
            <!--是否提现功能-->
            <a-form-item :label="l('提现功能')">
              <a-switch
                :disabled="entity.isAdmin == false || projectType == 'viewandset'"
                @change="handleWithdrawalChange"
                v-decorator="[
                  'isWithdrawal',
                  { valuePropName: 'checked', initialValue: false },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col span="12" v-show="showWithdrawalType">
            <a-form-item :label="l('提现发放方式')">
              <a-select
                :disabled="projectType == 'viewandset'"
                v-decorator="['withdrawalType',
                  {
                    rules: [],
                  }]"
                style="width: 100%"
              >
                <a-select-option v-for="item in withdrawalTypeData" :key="item.value" :value="item.value">
                  {{item.text}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutte="24">
          <a-col span="12">
            <!--是否开启个体工商注册-->
            <a-form-item :label="l('个体工商注册')">
              <a-switch
                  @change="sohoChange"
                  :disabled="projectType == 'viewandset'"
                  v-decorator="[
                  'isCommercialRegist',
                  { valuePropName: 'checked', initialValue: false },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col span="12">
            <!--是否需要签署协议后发放-->
            <a-form-item label="是否需要签署协议后发放">
              <a-switch
                  :disabled="projectType == 'viewandset'"
                  @change="handleSignedChange"
                  v-decorator="[
                  'isProjectAgreementSignedRequired',
                  { valuePropName: 'checked', initialValue: false },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24" v-show="showSoho">
          <a-col span="12">
            <a-form-item :label="l('报备单')">
              <a-select
                :disabled="projectType == 'viewandset'"
                v-decorator="[
                  'field2',
                  {
                    rules: [],
                  },
                ]"
                style="width: 100%"
              >
                <a-select-option
                  :value="item.value"
                  v-for="item in AllSohoData"
                  :key="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col span="12">
            <a-form-item :label="l('合作对象ID')">
              <a-input
                :placeholder="l('合作对象ID')"
                v-decorator="['field3', {}]"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutte="24">
          <a-col span="12">
            <!--是否开启打卡购买保险-->
            <a-form-item :label="l('打卡购买保险')">
              <a-switch
                  @change="insuranceChange"
                  :disabled="projectType == 'viewandset'"
                  v-decorator="[
                  'isBuyInsurancePunch',
                  { valuePropName: 'checked', initialValue: false },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col span="12" v-show="showInsurance">
            <!--提交执行记录次数-->
            <a-form-item label="保险配置">
              <a-select
                :disabled="projectType == 'viewandset'"
                v-decorator="[
                  'insuranceConfigId',
                  {
                    rules: [],
                  },
                ]"
                style="width: 70%"
              >
                <a-select-option
                  :value="item.value"
                  v-for="item in AllInsuranceData"
                  :key="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
              <a-button type="primary" shape="circle" icon="plus" @click="handleAddInsuranceCfg" style="margin-left:8px;">
              </a-button>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col span="24">
            <!--是否自动生成结算相关数据-->
            <a-form-item label="是否自动生成结算相关数据">
              <a-switch
                  :disabled="projectType == 'viewandset'"
                  v-decorator="[
                  'isAutoGenerateSettlementData',
                  { valuePropName: 'checked', initialValue: false },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col span="24">
            <!--备注-->
            <a-form-item :label="l('ProjectRemark')">
              <a-textarea
                :auto-size="{ minRows: 2, maxRows: 6 }"
                :placeholder="l('ProjectRemark')"
                v-decorator="['Remark', { rules: [] }]"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col span="24">
            <a-form-item
              class="btn--container"
              style="text-align: center; top: 30px"
            >
              <a-button type="button" @click="close()">
                {{ l("Cancel") }}
              </a-button>
              <a-button type="primary" html-type="submit">
                {{ l("Save") }}
              </a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
  </a-spin>
</template>

<script>
import { ModalComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { ProjectServiceProxy, ProjectDto } from "@/shared/service-proxies";
import { CommonServiceProxy,ProjectBusinessTypeConvert } from "@/shared/common-service";
import SelectPageList from "@/components/linggong/selectdata";
import AddInsurance from './insurance/addinsurance.vue';

export default {
  mixins: [ModalComponentBase],
  name: "create-or-edit-project",
  props: ["id", "projectType"],
  data() {
    return {
      // 获取到的数据
      entity: {},
      withdrawalTypeData: [{ text: '审核后发放', value: 1 }, { text: '无需审核', value: 2 }],
      cusAmount: 0,
      cusProvideCfgId: '',
      showBalance: false,
      spinning: false,
      showSoho: false,
      showInsurance: false,
      showWithdrawalType: false,
      AllSohoData: [],
      AllInsuranceData: [],
      TimeInterval: null,
      Proxy: "",
      ClientData: [],
      CompanyData: [],
      ContractData: [],
      InvoiceData: [],
      ProjectTypeData: [],
      verifiedWayOptions: [
        { label: "纯三要素", value: "4" },
        { label: "纯四要素", value: "5" },
        { label: "人脸识别", value: "9" },
        { label: "三要素+人脸", value: "0" },
        { label: "四要素+人脸", value: "2" },
      ],
      ncCodeOptions: [
        { label: "发票抬头", value: "0" },
        { label: "客户", value: "1" },
      ],
      invoiceOptions: [],
      configOptions: [],
      invoiceInfo: {
        invoiceInformationalName: null,
        invoiceInformationalId: null,
        arr: [],
      },
    };
  },
  computed: {
    _getId() {
      if (this.id) return this.id;
      else if (this._id) return this._id;
      else return null;
    },
  },
  components: {},
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "register" });
  },
  created() {
    this.fullData(); // 模态框必须,填充数据到data字段
    this.Proxy = new ProjectServiceProxy(this.$apiUrl, this.$api);
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.getProjectType();
    this.getAllContract();
    this.getAllSohoData();
    // this.getAllCustomerBaseInvoices("");
    this.getAllInsurance();
  },
  mounted() {
    setTimeout(() => {
      this.getData(this._getId);
    }, 500);
  },
  methods: {
    async handleQueryBalance () {
      if (this.cusProvideCfgId && this.cusProvideCfgId != '') {
        this.spinning = true;
        try {
          let res = await this.commonService.get({
            url: "/api/services/app/Project/GetProvideBalance",
            params: {
              cusProvideCfgId: this.cusProvideCfgId,
            },
          });

          this.cusAmount = res;
          this.spinning = false;
        } catch (error) {
          console.log(error);
          this.spinning = false;
        }
      }
    },
    handleCusProvideChange (val) {
      var rows = this.configOptions.filter(t => t.value == val);
      if (rows && rows.length > 0) {
        if (rows[0].accountType == 2) {
          this.showBalance = true;
          this.cusProvideCfgId = val;
          return;
        }
      }
      this.showBalance = false;
      this.cusProvideCfgId = null;
    },
    handleSignedChange(val) {
      if (val) {
        this.form.setFieldsValue({
          isRealNamePay: true
        });
      }
    },
    handleWithdrawalChange(val) {
      this.showWithdrawalType = val;
    },
    handleRealNamePayChange(val) {
      if (!val) {
        const res = this.form.getFieldsValue(['isProjectAgreementSignedRequired']);
        if (res.isProjectAgreementSignedRequired === true) {
          let _this = this;
          setTimeout(() => {
            _this.form.setFieldsValue({
              isRealNamePay: true
            });
          },100);
        }
      }
    },
    /**
     * 获取数据
     */
    getData(id) {
      this.spinning = true;
      if (id) {
        this.Proxy.GetProject(id)
          .finally(() => {
            this.spinning = false;
          })
          .then(async (res) => {
            if (!res) {
              return false;
            }
            if (res.withdrawalType) {
              res.withdrawalType = this.getWithdrawalType(res.withdrawalType);
            }
            this.entity = res;
            this.CompanyData.push({
              label: res.companyName,
              value: res.signCompanyId,
            });

            // invoiceInfo news~ 记录改版后的发票信息 中介
            this.invoiceInfo.invoiceInformationalName =
              res.invoiceInformationalName;
            this.invoiceInfo.invoiceInformationalId =
              res.invoiceInformationalId;

            //this._getSelectData(res.signCompanyId);
            await this.getOrganizationCustomerConfig(res.signCompanyId);
            this.form.setFieldsValue({
              ProjectName: this.entity.projectName,
              ProjectCode: this.entity.projectCode,
              ProjectType: this.getProjectTypeStr(this.entity.projectType),
              ProjectContract: this.entity.contractId,
              Company: {
                key: res.signCompanyId.toString(),
                label: res.companyName,
              },
              Invoice:
                this.entity.invoiceId ||
                this.entity.invoiceId === "00000000-0000-0000-0000-000000000000"
                  ? ""
                  : this.entity.invoiceId,
              ProjectTime: [res.beginDate, res.endDate],
              Remark: res.remark,
              ProjectFinCode: res.projectFinCode,
              Id: this.entity.Id,
              isAuditJoin: this.entity.isAuditJoin ? true : false,
              isRealNameJoin: this.entity.isRealNameJoin ? true : false,
              isRealNamePay: this.entity.isRealNamePay ? true : false,
              isCheckAge: this.entity.isCheckAge ? true : false,
              verifiedWay: res.verifiedWay.toString(),
              ncDefaultCode: res.ncDefaultCode.toString(),
              serviceChargeRate: res.serviceChargeRate,
              // invoiceInformationalId: res.invoiceInformationalId
              //   ? res.invoiceInformationalId.toUpperCase()
              //   : "",
              invoiceInformationalName: res.invoiceInformationalName,
              Field1: this.entity.field1,
              customerProvideConfigId: this.entity.customerProvideConfigId,
              ShowProjectName: this.entity.showProjectName,
              isWithdrawal: this.entity.isWithdrawal,
              withdrawalType: this.entity.withdrawalType,
              isCommercialRegist: this.entity.isCommercialRegist,
              isProjectAgreementSignedRequired:this.entity.isProjectAgreementSignedRequired,
              businessType: ProjectBusinessTypeConvert(this.entity.businessType),
              field2: this.entity.field2,
              field3: this.entity.field3,
              isBuyInsurancePunch: this.entity.isBuyInsurancePunch,
              insuranceConfigId: this.entity.insuranceConfigId,
              isAutoGenerateSettlementData:this.entity.isAutoGenerateSettlementData
            });
            this.showInsurance = this.entity.isBuyInsurancePunch;
            // this.form.setFieldsValue({
            //   field2: this.entity.field2
            // });
            if (this.entity.isCommercialRegist) {
              this.showSoho = true;
            }
            this.showWithdrawalType = this.entity ? this.entity.isWithdrawal ? this.entity.isWithdrawal : false : false;
            
            if (this.entity.customerProvideConfigId) {
              this.handleCusProvideChange(this.entity.customerProvideConfigId)
            }
            // if (this.ContractData.length > 0) {
            //   var items = this.ContractData.filter(p => p.id === this.entity.contractId);
            //   if (items && items.length > 0) {
            //     this.getAllCustomerBaseInvoices(items[0].customerId);
            //   }
            // }
          });
      } else {
        this.Proxy.getProjectCode()
          .finally(() => {
            this.spinning = false;
          })
          .then((res) => {
            if (res) {
              this.form.setFieldsValue({
                ProjectCode: res,
                verifiedWay: "5",
              });
            }
          });
      }
    },
    selectProjectModal() {
      ModalHelper.create(
        SelectPageList,
        {
          title: "选择发票信息", // 显示标题
          url: "/api/services/app/InvoiceInformational/GetSelectDataPaged", // 数据接口URL
          orgId: this.CompanyData[0].value,
        },
        {
          isChange: true,
          width: "860px",
          heigth: "650px",
        }
      ).subscribe((res) => {
        const { success, data } = res;
        if (success) {
          this.form.setFieldsValue({
            invoiceInformationalName: data.text,
          });
          this.invoiceInfo.invoiceInformationalName = data.text;
          this.invoiceInfo.invoiceInformationalId = data.value;
        }
      });
    },
    getProjectTypeStr(projectType) {
      switch (projectType) {
        case "RenLiWaiBao":
          return "1";
        case "XiangMuZiXun":
          return "2";
        case "TuiGuangFuWu":
          return "3";
        case "LuYanHuiZhan":
          return "4";
        case "XinXiJiShu":
          return "5";
        default:
          return "1";
      }
    },
    getWithdrawalType(type) {
      switch(type) {
        case 'AuditWithdrawal':
          return 1;
        case 'AutoWithdrawal':
          return 2;
        case 'None':
        default:
          return 0;
      }
    },
    /**
     * 提交表单
     */
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.spinning = true;
          let dto = new ProjectDto();
          console.warn(dto, values);
          dto = dto.formJson({
            ...values,
            verifiedWay: Number(values.verifiedWay),
            ncDefaultCode: Number(values.ncDefaultCode),
            invoiceInformationalId: this.invoiceInfo.invoiceInformationalId,
          });
          
          if (this.entity && this.entity.id) {
            dto.setId(this.entity.id);
          }
          if (values.isCommercialRegist && !values.field2) {
            this.$notification["error"]({
              message: "开启个体户注册需要选择报备单",
            });
            this.spinning = false;
            return false;
          }
          this.Proxy.CreateOrUpdateProject(dto)
            .finally(() => {
              this.spinning = false;
            })
            .then((res) => {
              this.$notification["success"]({
                message: this.l("SavedSuccessfully"),
              });
              this.success(true);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    getProjectType() {
      this.Proxy.getEnumKeyValueList(1).then((res) => {
        if (res && res.length > 0) {
          let array = res;
          for (let i = 0; i < array.length; i++) {
            let item = array[i];
            this.ProjectTypeData.push({ text: item.key, value: item.value });
          }
        }
      });
    },
    handleAddInsuranceCfg() {
      ModalHelper.create(AddInsurance, null,
        {
          isChange: true,
          width: "860px",
          heigth: "650px",
        }
      ).subscribe((res) => {
        const { success, data } = res;
        if (success) {
          this.getAllInsurance().then(() => {
            this.form.setFieldsValue({
              "insuranceConfigId": data
            })
          })
        }
      });
    },
    async getOrganizationCustomerConfig(organizationId) {
      this.configOptions = [];
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/CustomerProvideConfig/GetSelectData",
          params: {
            organizationId: organizationId,
          },
        });

        this.configOptions = res;
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },

    // 获取合同
    async getAllContract() {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Contract/GetPaged",
          params: {
            maxResultCount: 5000,
            skipCount: 0,
            Sorting: "CreationTime",
          },
        });
        this.ContractData = res.items
          .filter((m) => m.contractName != null)
          .map((item) => {
            return {
              ...item,
              label: item.contractName,
              value: item.id,
            };
          });
        // console.log(this.ContractData);
        // if (this.entity && this.entity.contractId) {
        //   this.ContractData = arr.filter(item => item.id === contractId)
        // }

        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    async ContractChange(value, e) {
      if (value) {
        // this.form.resetFields(["invoiceInformationalId"]);
        this.form.resetFields(["invoiceInformationalName"]);

        let obj = this.ContractData.filter((v) => v.id === value)[0];
        //this._getSelectData(obj.organizationUnitId);
        await this.getOrganizationCustomerConfig(obj.organizationUnitId);
        this.CompanyData = [
          { label: obj.organizationUnitName, value: obj.organizationUnitId },
        ];
        this.form.setFieldsValue({
          Company: {
            key: obj.organizationUnitId,
            label: obj.organizationUnitName,
          },
        });
      }
      // let customerId = '';
      // if (value) {
      //   let company = null;
      //   for (let i = 0; i < this.ContractData.length; i++) {
      //     let item = this.ContractData[i];
      //     if (item.id === value) {
      //       company = {
      //         id: item.organizationUnitId,
      //         name: item.organizationUnitName,
      //       };
      //       customerId = item.customerId;
      //       break;
      //     }
      //   }
      //   if (company !== null) {
      //     this.CompanyData.splice(0, this.CompanyData.length);
      //     this.CompanyData.push(company);
      //     this.form.setFieldsValue({
      //       Company: { key: company.id, label: company.name },
      //     });
      //   }
      // } else {
      //   this.CompanyData.splice(0, this.CompanyData.length);
      // }
      // if (customerId !== '') {
      //   this.getAllCustomerBaseInvoices(customerId);
      // }
    },
    async _getSelectData(orgId) {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/InvoiceInformational/GetSelectData",
          params: { orgId: orgId },
        });
        this.invoiceOptions = res.map((item) => {
          return {
            label: item.text,
            value: item.value,
          };
        });
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    getAllCustomerBaseInvoices(customerId) {
      this.Proxy.getAllCustomerBaseInvoices(customerId).then((res) => {
        this.InvoiceData.splice(0, this.InvoiceData.length);
        if (res.totalCount > 0) {
          for (let i = 0; i < res.items.length; i++) {
            let { id, title } = res.items[i];
            this.InvoiceData.push({ id: id, name: title });
            if (
              this.entity &&
              this.entity.invoiceId &&
              this.entity.invoiceId === id
            ) {
              this.form.setFieldsValue({
                Invoice: { key: id, label: title },
              });
            }
          }
        }
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    close() {
      this.$emit("emitclose");
      this.success(true);
    },
    async getAllSohoData() {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/SohoIndustry/GetAll",
        });
        this.AllSohoData = res
          .filter((m) => m.industryName != null)
          .map((item) => {
            return {
              label: item.enterpriseName + "-" + item.industryName,
              value: item.id,
            };
          });
        this.spinning = false;
      } catch (error) {
        console.error(error);
        this.spinning = false;
      }
    },
    async getAllInsurance () {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Project/GetAllInsuranceConfig",
        });
        this.AllInsuranceData = res
          .map((item) => {
            return {
              label: item.appKey + "-" + item.provide,
              value: item.id,
            };
          });
        this.spinning = false;
      } catch (error) {
        console.error(error);
        this.spinning = false;
      } 
    },
    sohoChange(e) {
      this.showSoho = e;
    },
    insuranceChange(e) {
      this.showInsurance = e;
    }
  },
};
</script>

<style lang="less" scoped>
.btn--container .ant-form-item-children {
  display: block;
  text-align: center;
}

.pleaseSelect-text {
  font-size: 14px;
  padding: 0 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 0;
}
.s-formbody {
  height:550px;
  max-height:550px;
  overflow-y:scroll;
  overflow-x:hidden;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}
.s-formbody::-webkit-scrollbar {
  width: 0 !important;
}
</style>
