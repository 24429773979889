<template>
    <a-spin :spinning="spinning">
        <div class="modal-header">
            <div class="modal-title">
                <span>{{ l("ChooseTrain") }}</span>
            </div>
        </div>
        <a-form :form="form" @submit="handleSubmit" layout="horizontal">
            <a-row :gutter="24">
                <a-col span="12">
                    <!-- ��ѵ���� -->
                    <a-form-item :label="l('ChooseTrain')">
                        <a-input :placeholder="l('ChooseTrain')" @click="ChooseOneTrain" readonly v-decorator="['TrainName', {rules: []}]" />
                    </a-form-item>
                </a-col>
                <a-col span="12">
                    <!-- ��Чʱ�� -->
                    <a-form-item :label="l('TrainHeaderEffectiveTime')">
                        <a-range-picker :placeholder="[l('StartTime'), l('EndTime')]"
                                        valueFormat="YYYY-MM-DD HH:mm:ss" show-time disabled
                                        v-decorator="[ 'EffectiveTime', { rules: []} ]">
                            <template slot="dateRender" slot-scope="current">
                                <div class="ant-calendar-date">
                                    {{ current.date() }}
                                </div>
                            </template>
                        </a-range-picker>
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row :gutter="24">
                <a-col span="12">
                    <!--ѧϰ֪ͨ-->
                    <a-form-item :label="l('StudyNotifyUrl')">
                        <a-input :placeholder="l('StudyNotifyUrl')" disabled v-decorator="['StudyNotifyUrl', {rules: []}]" />
                    </a-form-item>
                </a-col>
                <a-col span="12">
                    <!--����֪ͨ-->
                    <a-form-item :label="l('ExamNotifyUrl')">
                        <a-input :placeholder="l('ExamNotifyUrl')" disabled v-decorator="['ExamNotifyUrl', {rules: []}]" />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row :gutter="24">
                <a-col span="12">
                    <!--У��url-->
                    <a-form-item :label="l('CheckUri')">
                        <a-input :placeholder="l('CheckUri')" disabled v-decorator="['CheckUri', {rules: []}]" />
                    </a-form-item>
                </a-col>
                <a-col span="12">
                    <!--�ɿ��Դ���-->
                    <a-form-item :label="l('CanExamNum')">
                        <a-input-number :placeholder="l('CanExamNum')" disabled style="width:100%;" :step="1" :min="1" v-decorator="['CanExamNum', {rules: []}]" />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row :gutter="24">
                <a-col span="12">
                    <!--��ѵ����-->
                    <a-form-item :label="l('TrainDescription')">
                        <a-textarea :placeholder="l('TrainDescription')" disabled v-decorator="['TrainDescription',{rules: []}]" :rows="4" />
                    </a-form-item>
                </a-col>
                <a-col span="12">
                    <!--�Ƿ����-->
                    <a-form-item :label="l('TrainHeaderIsCheck')">
                        <a-switch :checked-children="l('Yes')" disabled v-model="IsRequiredCourse" :un-checked-children="l('No')" v-decorator="['IsCheck',{rules: []}]" />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row :gutter="24">
                <a-col span="12">
                    <!--ͨ�����Է���-->
                    <a-form-item :label="l('PassNum')">
                        <a-input-number step="1" :min="1" disabled style="width:100%;" v-decorator="['PassNum',{rules: []}]" />
                    </a-form-item>
                </a-col>
                <a-col span="12">
                    <!--����ʱ��(����)-->
                    <a-form-item :label="l('ExamLength')">
                        <a-input-number step="1" :min="1" disabled style="width:100%;" :placeholder="l('ExamLength')" v-decorator="['ExamLength',{rules: []}]" :rows="4" />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row :gutter="24">
                <a-col span="12">
                    <!--����ʱ��-->
                    <a-form-item :label="l('ExamTime')">
                        <a-range-picker :placeholder="[l('StartTime'), l('EndTime')]" disabled
                                        valueFormat="YYYY-MM-DD HH:mm:ss" show-time
                                        v-decorator="[ 'ExamTime', { rules: []} ]">
                            <template slot="dateRender" slot-scope="current">
                                <div class="ant-calendar-date">
                                    {{ current.date() }}
                                </div>
                            </template>
                        </a-range-picker>
                    </a-form-item>
                </a-col>
                <a-col span="12">
                    <!--����ģ��-->
                    <a-form-item :label="l('CurrentTotalNum')">
                        <a-input-number readonly style="width: 100%;" disabled v-decorator="[ 'CurrentTotalNum', { rules: []} ]" />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row :gutter="24">
                <a-col span="12">
                    <!--��ѡ��-->
                    <a-form-item :label="l('SingerTitle')">
                        {{l("PerTitleNum")}}:&nbsp;<a-input-number :step="1" disabled :min="1" style="width:80px;" v-decorator="[ 'SinglePerTitleNum', { rules: []} ]" />
                        <span style="width:20px;">&nbsp;</span>
                        {{l("TotalTitle")}}:&nbsp;<a-input-number :step="1" disabled :min="1" style="width:80px;" v-decorator="[ 'SingleTotalTitle', { rules: []} ]" />
                    </a-form-item>
                </a-col>
                <a-col span="12">
                    <!--��ѡ��-->
                    <a-form-item :label="l('MultipleTitle')">
                        {{l("PerTitleNum")}}:&nbsp;<a-input-number :step="1" disabled :min="1" style="width:80px;" v-decorator="[ 'MultPerTitleNum', { rules: []} ]" />
                        <span style="width:20px;">&nbsp;</span>
                        {{l("TotalTitle")}}:&nbsp;<a-input-number :step="1" disabled :min="1" style="width:80px;" v-decorator="[ 'MultTotalTitle', { rules: []} ]" />
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row :gutter="24">
                <a-col span="6" offset="18">
                    <a-form-item class="btn--container" style="text-align: center">
                        <a-button type="button" @click="close()">
                            {{ l("Cancel") }}
                        </a-button>
                        <a-button type="primary" html-type="submit">
                            {{ l("Save") }}
                        </a-button>
                    </a-form-item>
                </a-col>
            </a-row>
        </a-form>
    </a-spin>
</template>

<script>
    import { ModalComponentBase } from "@/shared/component-base";
    import { ModalHelper } from "@/shared/helpers";
    import TrainList from "./trainlist.vue";

    export default {
        mixins: [ModalComponentBase],
        name: "create-or-edit-project",
        data() {
            return {
                // ��ȡ��������
                entity: {},
                spinning: false,
                singleNum: 0,
                singleTotal: 0,
                multNum: 0,
                multTotal: 0,
                IsRequiredCourse: false,
                TrainId: null
            };
        },
        components: {},
        beforeCreate() {
            this.form = this.$form.createForm(this, { name: "trainsave" });
        },
        created() {
            this.fullData(); // ģ̬�����,������ݵ�data�ֶ�
        },
        methods: {
            /**
             * �ύ����
             */
            handleSubmit(e) {
                e.preventDefault();
                this.form.validateFieldsAndScroll((err, values) => {
                    if (!err) {
                        let data = {
                            projectId: this.projectId,
                            trainId: this.TrainId,
                            trainName: values.TrainName,
                            trainIntroduce: values.TrainDescription,
                            isRequiredCourse: this.IsRequiredCourse
                        };
                        let options = {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json-patch+json",
                            },
                            url: this.$apiUrl + '/api/services/app/Project/ProjectTrainSave',
                            data: data
                        }
                        
                        this.spinning = true;
                        this.$api.request(options).then(res => {
                            if (res.status == 200) {
                                abp.message.success(this.l("SuccessfullyOperation"));
                                this.success(true);
                            } else {
                                abp.message.warn(res.statusText);
                            }
                        }).catch(e => {
                            console.error(e);
                            abp.message.warn(this.l("DefaultErrorMessage"));
                        }).finally(() => {
                            this.spinning = false;
                        });
                    }
                });
            },
            ChooseOneTrain() {
                ModalHelper.create(TrainList, null, { isChange: true, width: "1200px" }).subscribe(res => {
                    if (typeof res != 'boolean') {
                        let values = res;
                        this.TrainId = res.id;
                        this.form.setFieldsValue({
                            TrainName: values.name,
                            TrainDescription: values.description,
                            EffectiveTime: [values.trainTime, values.endTrainTime],
                            ExamTime: [values.testTime, values.endTestTime],
                            ExamLength: values.testLengthTime,
                            CanExamNum: values.testCount,
                            PassNum: values.passScore,
                            IsCheck: values.isRequiredCourse,
                            SinglePerTitleNum: values.template.filter(e => e.questionType == 1)[0].score,
                            SingleTotalTitle: values.template.filter(e => e.questionType == 1)[0].num,
                            MultPerTitleNum: values.template.filter(e => e.questionType == 2)[0].score,
                            MultTotalTitle: values.template.filter(e => e.questionType == 2)[0].num,
                            StudyNotifyUrl: values.studyNotifyUrl,
                            ExamNotifyUrl: values.testNotifyUrl,
                            CheckUri: values.verificationUrl,
                            CurrentTotalNum: this.getTotal(values.template)
                        });
                        this.IsRequiredCourse = values.isCheck;
                    }
                });
            },
            getTotal(template) {
                let total = 0;
                for (let i = 0; i < template.length; i++) {
                    let item = template[i];
                    total += item.num * item.score;
                }
                return total;
            }
        },
    };
</script>

<style lang="less" scoped>
    .btn--container .ant-form-item-children {
        display: block;
        text-align: center;
    }

    .pleaseSelect-text {
        font-size: 14px;
        padding: 0 14px;
        text-align: center;
        color: rgba(0, 0, 0, 0.65);
        font-weight: 400;
        line-height: 30px;
        margin-bottom: 0;
    }
</style>
