var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"spinning":_vm.spinning}},[_c('section',[_c('ele-table',{attrs:{"columns":_vm.columns,"table-data":_vm.tableData,"total-items":_vm.totalItems,"is-full":false,"actionsType":_vm.actionsType,"current-page":_vm.pageNumber,"hide-row-selection":true,"hide-pagination":true},on:{"emitSelectChange":_vm.selectChange}},[_c('a-row',{staticStyle:{"text-align":"right"},attrs:{"gutter":24,"type":"flex"}},[_c('a-col',{staticClass:"gutter-row",attrs:{"span":6,"offset":"18"}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.addTemplete}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v(_vm._s(_vm.l("添加")))],1)],1)],1)],1)],1),_c('a-modal',{attrs:{"centered":"","title":_vm.seclectModalParams.title,"visible":_vm.seclectModalParams.visible,"confirm-loading":_vm.seclectModalParams.confirmLoading,"cancelText":_vm.seclectModalParams.cancelText,"okText":_vm.seclectModalParams.okText,"maskClosable":_vm.seclectModalParams.maskClosable,"destroyOnClose":_vm.seclectModalParams.destroyOnClose,"width":_vm.seclectModalParams.width},on:{"ok":_vm.seclectModalParams.confirm,"cancel":_vm.seclectModalParams.cancel}},[_c('div',[_c('a-form',_vm._b({attrs:{"form":_vm.form1}},'a-form',_vm.formItemLayout,false),[_c('a-form-item',{attrs:{"label":"任务模版","has-feedback":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'taskTemplateId',
              { rules: [{ required: true, message: '请选择任务模板' }] } ]),expression:"[\n              'taskTemplateId',\n              { rules: [{ required: true, message: '请选择任务模板' }] },\n            ]"}],attrs:{"placeholder":"请选择"}},_vm._l((_vm.seclectModalParams.selectList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.text)+" ")])}),1)],1)],1)],1)]),_c('a-modal',{attrs:{"centered":"","title":_vm.inputModalParams.title,"visible":_vm.inputModalParams.visible,"confirm-loading":_vm.inputModalParams.confirmLoading,"cancelText":_vm.inputModalParams.cancelText,"okText":_vm.inputModalParams.okText,"maskClosable":_vm.inputModalParams.maskClosable,"destroyOnClose":_vm.inputModalParams.destroyOnClose,"width":_vm.inputModalParams.width},on:{"ok":_vm.inputModalParams.confirm,"cancel":_vm.inputModalParams.cancel}},[_c('div',[_c('a-form',_vm._b({attrs:{"form":_vm.form2}},'a-form',_vm.formItemLayout,false),[_c('a-form-item',{attrs:{"label":"作废原因","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'invalidReason',
              {
                rules: [
                  { required: true, message: this.l('ThisFieldIsRequired') } ],
              } ]),expression:"[\n              'invalidReason',\n              {\n                rules: [\n                  { required: true, message: this.l('ThisFieldIsRequired') },\n                ],\n              },\n            ]"}],attrs:{"type":"textarea","placeholder":_vm.l('请输入')}})],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }