<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title">
        <span>项目配置</span>
      </div>
    </div>
    <div>
      <a-tabs default-active-key="1" @change="callback">
        <a-tab-pane key="1" v-if="type === 'viewandset'">
          <span slot="tab">
            <a-icon type="setting" />
            {{ l("基本信息") }}
          </span>
          <CreateOrUpdate
            @emitclose="close"
            :id="ProjecId"
            :projectType="type"
            :isShow="true"
          ></CreateOrUpdate>
        </a-tab-pane>
        <a-tab-pane key="2" force-render>
          <span slot="tab">
            <a-icon type="profile" />
            {{ l("协议管理") }}
          </span>
          <templete-list @emitclose="close" :id="ProjecId"></templete-list>
        </a-tab-pane>
        <a-tab-pane key="3" v-if="type != 'viewandset'">
          <span slot="tab">
            <a-icon type="profile" />
            {{ l("关联表单") }}
          </span>
          <project-form @emitclose="close" :id="ProjecId"></project-form>
        </a-tab-pane>
        <a-tab-pane key="4">
          <span slot="tab">
            <a-icon type="profile" />
            {{ l("任务配置") }}
          </span>
          <task-form @emitclose="close" :id="ProjecId"></task-form>
        </a-tab-pane>
        <a-tab-pane key="5">
          <span slot="tab">
            <a-icon type="unordered-list" />
            {{ l("培训配置") }}
          </span>
          <train-list @emitclose="close" :id="ProjecId"></train-list>
        </a-tab-pane>
        <a-tab-pane key="6" v-if="type != 'viewandset'">
          <span slot="tab">
            <a-icon type="setting" />
            {{ l("项目授权") }}
          </span>
          <authed-user-list
            @emitclose="close"
            :_id="ProjecId"
            :id="ProjecId"
            :_projectType="type"
            :isShow="true"
          ></authed-user-list>
        </a-tab-pane>
        
      </a-tabs>
    </div>
  </a-spin>
</template>
<script>
import { ModalComponentBase } from "@/shared/component-base";
import TempleteList from "../project-agreement/templete-list";
import ProjectForm from "../project-form/project-form";
import TaskForm from "../task-form/task-form";
import TrainList from "../train/list";
import AuthedUserList from "../project-form/authed-user-list";
import { ModalHelper } from "@/shared/helpers";
import CreateOrUpdate from "../createproject.vue";
export default {
  name: "task-details",
  mixins: [ModalComponentBase],
  components: {
    TempleteList,
    ProjectForm,
    TaskForm,
    TrainList,
    AuthedUserList,
    CreateOrUpdate,
  },
  data() {
    return {
      spinning: false,
      ProjecId: "",
      Type: "",
    };
  },
  created() {
    this.fullData();
  },
  mounted() {
    this.ProjecId = this.id;
    this.Type = this.type;
  },
  methods: {
    callback(key) {
      
    },
  },
};
</script>